import React from 'react'
import PieChart from '../../icons/pie-chart.png'
import { Typography } from '@mui/material'
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import './PriorityDistribution.css'

Chart.register(ArcElement, Tooltip, Legend, Title); // Create a chart with the necessary elements

/*

PriorityDistribution component that renders the priority distribution chart.
It displays the percentage of high, medium, and low priority issues in the project.

 */
const PriorityDistribution = () => {

    // Data for the chart
    const data = {
        labels: ['High', 'Medium', 'Low'],
        datasets: [{
            data: [],
            backgroundColor: [
                'rgba(248,113,113,255)',
                'rgba(252,211,77,255)',
                'rgba(68,165,255,255)'
            ],
            borderColor: [
                'rgba(248,113,113,255)',
                'rgba(252,211,77,255)',
                'rgba(68,165,255,255)'
            ],
            hoverOffset: 4,
        }],
        radius: 50,

    };

    // Chart design options
    const chartOptions =data!==null? {
        cutout: '70%',
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = data.labels[context.dataIndex] || '';
                        const value = context.formattedValue;
                        const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
                        const percentage = Math.round((value / total) * 100);
                        return `${label}: ${percentage}%`;
                    }
                }
            },
            legend: {
                display: false, // Hides the labels on top of the chart
            },
        },
        aspectRatio: 3,
        elements: {
            arc: {
              hoverRadius: 0, // Adjust this value to control the size of the label square when hovering
              borderWidth: 0.01, // Removes the border of the label square
            }
          }
    }:null;


    return (
        <div className='pdCoverage'>
            <Typography variant='h5' className='pdCoverage-title'>
                Priority Distribution
                </Typography>
                {data?.length>0 ? <div className='httpChart'>
                <Doughnut
                    data={data}
                    options={chartOptions}
                />

            </div> :
                <>
                    <img src={PieChart} alt='Pie Chart' className='httpCoverage-image' />
                    <Typography variant='h6'>
                        Whoops! No data<br></br> available at the moment.
                    </Typography>
                </>
            }
        </div>
    )
}

export default PriorityDistribution
