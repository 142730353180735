
import { AiOutlineImport } from "react-icons/ai";
import { LuTrash } from "react-icons/lu";


const HeaderRow = ({ setPopOpen, headerKey, ind, headers, setHeaders, val, popOpen, setNewKey, setValue, apiid }) => {

  const handleHeaderDelete = () => {
    let temp = [...headers]
    temp.splice(ind, 1)
    let formattedHeaders = {}
    temp.forEach(header => formattedHeaders[header[0]] = header[1])
    setHeaders(formattedHeaders)
  }

  return <div className="">
    <div className="grid grid-cols-12 border-b border-[#2C2E33]">
      <div className="col-span-1 flex items-center justify-center"><input
        // style={{ marginRight: "5px" }}
        type="checkbox"
        id="apiSelected"
        name="apiSelected"
      /></div>
      <div className="col-span-4 px-3 py-2 border-x border-[#2C2E33]">{headerKey}</div>
      <div className="col-span-6 group px-3 py-2 flex justify-between items-center">
        <span className={"text-wrap break-words w-full pr-4 overflow-x-auto no-scrollbar " + ((popOpen?.headerKeys[apiid] && headerKey in popOpen?.headerKeys[apiid]) ? "text-[#E27AAB]": "")}>{val}</span>
        {/* <span className="text-wrap break-words w-full pr-4 overflow-x-auto no-scrollbar">{val}</span> */}
        <div className="hidden cursor-pointer group-hover:block"><AiOutlineImport onClick={() => {
          setPopOpen({value: true, type: "header", headerKey, headerKeys: popOpen.headerKeys});
          setNewKey(headerKey);
          setValue(val);
          }} className="bg-[#494949] p-1 flex items-center justify-center rounded cursor-pointer" size={20} /></div>
      </div>
      <div onClick={handleHeaderDelete} className="col-span-1 text-[#494949] cursor-pointer flex items-center justify-center border-l border-[#2C2E33]" ><LuTrash /></div>
    </div>
  </div>
}

export default HeaderRow;
