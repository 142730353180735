import React, { useState, useEffect } from "react";
import { LuTrash, LuSearch } from "react-icons/lu";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { getMethodClasses } from "../../../utils/ui";


const APISequence = ({ allApis, setCurrentApi, addedApis, currentApi, setReqBody, setResContent, setHeaders, setAddedApis }) => {
  const [apiCount, setApiCount] = useState(1);
  const [show, setShow] = useState({ id: null, val: false })
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (allApis) {
      setData({ original: allApis, current: allApis })
    }
  }, [allApis])

  function extractKeys(obj, prefix = '') {
    let keys = [];

    for (const [key, value] of Object.entries(obj)) {
      const fullKey = prefix ? `${prefix}.${key}` : key;

      if (Array.isArray(value)) {
        // Handle arrays
        value.forEach((item, index) => {
          const arrayKey = `${fullKey}[${index}]`;
          if (typeof item === 'object' && item !== null) {
            keys = keys.concat(extractKeys(item, arrayKey));
          } else {
            keys.push({
              name: key,
              path: arrayKey,
            });
          }
        });
      } else if (typeof value === 'object' && value !== null) {
        keys = keys.concat(extractKeys(value, fullKey));
      } else {
        keys.push({
          name: key,
          path: fullKey
        });
      }
    }

    return keys;
  }

  const getVariables = (content) => {
    return content ? extractKeys(typeof content === 'string' ? JSON.parse(content) : content) : [ // The values that will be stored
      {
        name: 'message',
        path: 'response.data.message'
      }
    ]
  }

  const changeApi = (apiID, formattedApi) => {
    const selected = allApis.filter((api) => api.id === apiID);
    setCurrentApi(formattedApi);
    setHeaders(selected[0].source.request.headers)
    setReqBody(selected[0].source.request.body ? JSON.stringify(selected[0].source.request.body, null, 4) : 'NA');
    let formattedResContent = ""
    if (typeof (selected[0].source.response.content) === 'object') {
      formattedResContent = selected[0].source.response.content ? JSON.stringify(selected[0].source.response.content, null, 4) : 'NA'
    } else {
      let temp = JSON.parse(selected[0].source.response.content ?? '{}')
      formattedResContent = JSON.stringify(temp, null, 4)
    }
    setResContent(formattedResContent);
  }

  const handleApiSelection = (apiID, index) => {
    if (index === apiCount - 1) {
      setApiCount(apiCount + 1)
    }
    const selected = allApis.filter((api) => api.id === apiID);
    let formattedApi = {
      id: selected[0].id,
      name: selected[0].name,
      description: [],
      baseURL: '',
      endpoint: selected[0].source.request.path,
      method: selected[0].source.request.method,
      headers: selected[0].source.request.headers,
      body: selected[0].source.request.body,
      expectedStatus: selected[0].source.response?.status_code,
      assertions: [],
      variables: getVariables(selected[0].source.response.content ?? {}),
      replace_variables: [],
      step_number: index + 1,
      responseSchema: {},
      response: selected[0].source.response
    }
    changeApi(apiID, formattedApi);

    if (index < addedApis.length) {
      addedApis[index] = formattedApi;
    } else {
      addedApis.push(formattedApi)
    }
    setAddedApis([...addedApis]);
  };

  const handleApiDelete = (ind) => {
    const temp = addedApis.filter((api, index) => index !== ind);
    setApiCount(apiCount - 1)
    setAddedApis(temp);

  }

  const handleSearch = (value) => {
    setSearch(value);
    const searchedData = data?.original?.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
    setData({ ...data, current: value === '' ? data?.original : searchedData })
  }

  const renderApiSequence = () => {
    const apiSequence = [];
    for (let i = 0; i < apiCount; i++) {
      apiSequence.push(
        <div className="mt-4 w-full" key={i}>
          <div
            className={"p-3 relative bg-inherit no-scrollbar rounded-md hover:bg-[#0F1011] border group border-[#2C2E33] w-full text-white outline-none active:outline-none text-[#A0A0A0] " + (i === apiCount - 1 ? "bg-[#1D1E20] text-white" : "")}
          // onChange={(e) => { handleApiSelection(e.target.value, i) }}

          >
            <div className="flex justify-between items-center group">
              <div className="flex gap-2 items-center w-full" onClick={() => (addedApis[i]?.id === currentApi?.id || i + 1 === apiCount) ? setShow({ id: i, val: !show.val }) : changeApi(addedApis[i].id, addedApis[i])}>
                {addedApis[i] && <span className={"rounded-full px-2 py-0.5 text-xs min-w-[50px] text-center " + getMethodClasses(addedApis[i]?.method)}>{addedApis[i]?.method}</span>}
                <span className="text-dashboard-text-gray group-hover:text-white">{addedApis[i] ? addedApis[i].name : "Select an API"}</span>
              </div>
              <div className="flex gap-2 text-dashboard-text-gray">
                {addedApis[i] && <LuTrash className="cursor-pointer hover:text-dashboard-dark-202 z-5" onClick={() => handleApiDelete(i)} />}
                <MdOutlineArrowBackIos onClick={() => (addedApis[i]?.id === currentApi?.id || i + 1 === apiCount) ? setShow({ id: i, val: !show.val }) : changeApi(addedApis[i].id, addedApis[i])} className={"cursor-pointer " + ((show.val && show.id === i) ? "rotate-90" : "-rotate-90")} />
              </div>
            </div>
            {(show.val && show.id === i) && <div className="absolute z-10 p-1.5 top-[50px] left-0 w-full bg-dashboard-dark-800 rounded-md max-h-[40vh] overflow-y-auto no-scrollbar border border-dashboard-dark-400">
              <div className="rounded-md border border-dashboard-dark-400 flex gap-2 items-center mb-2 px-2 py-1.5">
                <LuSearch className="text-dashboard-text-gray-800 text-sm" />
                <input value={search} onChange={e => handleSearch(e.target.value)} className="bg-inherit focus:outline-none active:outline-none w-full" placeholder="Search" />
              </div>
              {data?.current?.map((api) => (
                <div onClick={() => {
                  handleApiSelection(api.id, i)
                  setShow({ id: i, val: !show.val })
                }} className="flex gap-2 px-2 py-1.5 items-center hover:bg-[#141516] rounded-md border border-transparent hover:border-dashboard-dark-400">
                  <span className={"rounded-full px-2 py-0.5 text-xs min-w-[50px] text-center " + getMethodClasses(api?.source?.request?.method)}>{api?.source?.request?.method}</span>
                  <span>{api.name}</span>
                </div>
              ))}
            </div>}
          </div>
        </div>
      );
    }
    return apiSequence;
  }

  return (
    <div className="flex flex-col justify-start h-[80%] overflow-y-auto no-scrollbar">
      {renderApiSequence()}
    </div>
  );
};

export default APISequence;
