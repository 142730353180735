import React from 'react'
import './ResetPassword.css'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from '../../Actions/userActions';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

/*

ResetPassword component that renders the reset password page.
It contains an input field for the email address and a send button.
It also contains a link to go back to the login page.
It displays a message to enter the email to receive a link to reset the password.


*/
export const ResetPassword = () => {
    const [input, setInput ] = useState('')
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    const dispatch = useDispatch()

    const handleClick = async () => {
        // console.log(input)
        try {
            await dispatch(resetPassword(input)); // Dispatch resetPassword action
          } catch (error) {
            // On error
            setAlertSeverity("error");
            setAlertMessage("Invalid email address.");
            setSnackbarOpen(true);
          }
    }

    return (
        <div className='reset-pass-container'>
            <div className='reset-pass-page'>
                <div className="reset-pass-header">
                    <div className="reset-pass-title">
                        <Typography sx={{ color: "white" }} variant="h4" className="reset-password-title">Reset Password</Typography>
                    </div>
                    <div className="reset-pass-sub">
                        <Typography variant='h6'>Enter your email to which we will send you a link to<br></br> reset your password</Typography>
                    </div>
                </div>
                <div className="reset-pass-body">
                    <div className="reset-pass-input">
                        <input
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder='Enter your email address'
                        />
                    </div>
                    <div className="send-btn" onClick={handleClick}>
                        <button>Send</button>
                    </div>
                    <div className="back-login">
                        <a href='/login'>← Back to Login</a>
                    </div>
                </div>
            </div>
        </div>
    )
}