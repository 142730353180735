const ProfileInput = ({ label, placeholder, data, handleProfileChange }) => (
    <div className="">
        <div className="flex flex-col gap-[12px]">
            <div className="flex text-[#B0B0B0]">
                {label}
                <span className="text-[#D9509B]">*</span>
            </div>
            <div className="w-full flex gap-[50px]">
                <div className="relative w-[548px]">
                    <input
                        value = {data[label.toLowerCase()]}
                        onChange={handleProfileChange}
                        type="text"
                        placeholder={placeholder}
                        className="w-full px-[14px] pr-[40px] py-[10px] rounded-[6px] text-text-primary bg-[#0F1011] border border-[#2C2E33] transition-colors duration-200 focus:outline-none focus:border-primary"
                    />
                </div>
            </div>
        </div>
    </div>
);

export default ProfileInput;