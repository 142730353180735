import APISequence from "../APISequence/APISequence";
import { IoIosArrowDown } from "react-icons/io";

const AuthModel = ({ allApis, setCurrentApi, addedApis, tokenOpen, setTokenOpen, setStatus, setExpiryOpen, expiryOpen }) => {

  return (
    <div className="grid grid-cols-2 gap-8 w-4/5 margin-auto">
      <div className="bg-[#17181A] border border-[#2E3138] rounded-lg flex flex-col h-full">
        <div className="py-4 border-b border-[#2C2E33] text-center">Select APIs that generate access tokens</div>
        <div className="px-4 py-8 min-h-[60vh] max-w-[70vh] overflow-y-scroll no-scrollbar">
          <APISequence allApis={allApis} addedApis={addedApis} setCurrentApi={setCurrentApi} />
        </div>
        <div className="py-4 border-t border-[#2C2E33] justify-self-end flex justify-center items-center">
          <div className="cursor-pointer bg-[#D9509B] px-4 py-1.5 max-w-fit rounded-md border border-[#FF86BE] hover:bg-[#CC3F8D]" onClick={() => setStatus("chat")}>Proceed to API Verification</div>
        </div>
      </div>
      <div className="p-4">
        Select Auth Variables For The API
        <div className="mt-4 w-full px-4 py-3 bg-[#0F1011] no-scrollbar rounded-md w-full text-white outline-none focus:outline-none border border-[#2C2E33]">
          <div className="flex items-center justify-between cursor-pointer" onClick={() => setTokenOpen(!tokenOpen)}>auth_token <IoIosArrowDown /></div>
          {tokenOpen ? <input placeholder="Access token" className="my-4 px-4 py-3 border border-[#2c2e33] bg-inherit w-full rounded-md" /> : null}
        </div>
        <div className="mt-2 w-full  px-4 py-3 bg-[#0F1011] no-scrollbar rounded-md w-full text-white outline-none focus:outline-none border border-[#2C2E33]">
          <div className="flex items-center justify-between cursor-pointer" onClick={() => setExpiryOpen(!expiryOpen)}>expiry_time <IoIosArrowDown /></div>
          {expiryOpen ? <input placeholder="Expiry" className="my-4 px-4 py-3 border border-[#2c2e33] bg-inherit w-full rounded-md" /> : null}
        </div>
        <div className="mt-4 bg-[#D65A5A33] flex max-w-fit gap-2 p-0.5 px-2 rounded items-center text-[#FECACA] text-sm">
          <div className="bg-[#F87171] w-[10px] h-[10px] rounded-full" />
          The token expires after 12 hours
        </div>
      </div>
    </div>
  );
}

export default AuthModel;