import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import "./ChatBot.css";
import serverLogo from "./logo2.png";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import { useSelector } from "react-redux";
import Loader from "../IntegrateSDK/CollectAPI/Loader/Loader";
import { useDispatch } from "react-redux";
import { getAllAPIs } from "../../Actions/tcActions";
import APICard from "./APICard/APICard";
import { useAuth } from "../../AuthContext";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { config } from "../../config";
import ReqResBody from "../ReqResBody/ReqResBody";
import AuthModel from "./authenticationModal/authenticaticationModal";
import ChatUI from "./ChatUI/ChatUI";

let dev = config.dev

let socketioURL = dev === false ? "https://api-sample-gen-7qxc7hlaka-uc.a.run.app" : "http://localhost:8030"


/*

The ChatBot component is a chat application that communicates with a server using websockets.
It uses the socket.io-client library to establish a connection with the server.
It uses the useState hook to manage the state of the messages.
It uses the useEffect hook to listen for messages from the server.
It contains a form with a textarea for the user to input messages.
It sends messages to the server when the user submits the form.
It displays the messages from the server and the user in the chat area.

*/
const ChatBot = ({ setPageStatus }) => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const { loading: userLoading } = useSelector((state) => state.user);
  const { logout } = useAuth();
  const apis = useSelector((state) => state.apis);
  const [input, setInput] = useState("");
  const [signature, setSignature] = useState(""); // New state for signature
  const [req, setReq] = useState(""); // New state for request
  const [isFirstMessage, setIsFirstMessage] = useState(true); // Track if it's the first message
  const [allApis, setAllApis] = useState(null);
  const [currentApiIndex, setCurrentApiIndex] = useState(0);
  const [socket, setsocketConection] = useState(null);
  const [selectedApi, setSelectedApi] = useState(null);
  const [chatLoading, setChatLoading] = useState(false);
  const [enableMessage, setEnableMessage] = useState(false);
  const [status, setStatus] = useState("auth");
  const [currentApi, setCurrentApi] = useState(null);
  const [addedApis, setAddedApis] = useState({});
  const [tokenOpen, setTokenOpen] = useState(false);
  const [expiryOpen, setExpiryOpen] = useState(false);


  useEffect(() => {
    const socket = io(socketioURL);
    setsocketConection(socket);

    socket.on("message", (message) => {
      // console.log("Received message:", message);
      setEnableMessage(true);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "Server",
          message: typeof message === 'string' ? message : message.message || JSON.stringify(message)
        },
      ]);
    });

    return () => {
      socket.off("message");
      socket.disconnect();
    };
  }, []);


  useEffect(() => {
    if (status !== "auth") {
      setSelectedApi(currentApi?.id);
    
    if (allApis && allApis?.length > 0) {
      let messageToSend;
      if (isFirstMessage) {
        // console.log("Sending First Message ");
        const reqNames = allApis[0].api;
        // console.log("reqNames", reqNames);
        messageToSend = { signature: true, req: reqNames, input: null }; // Include signature and req for the first message
        if (socket && reqNames) {
          socket.emit('message', messageToSend);
          // console.log("Sending First Message to Server");
          setIsFirstMessage(false); // Update to indicate first message has been sent
        }
      } else {
        messageToSend = { input: input }; // Only include input for subsequent messages
      }
    }
  }
  }, [status, allApis]);

  const sendMessage = (e) => {
    setEnableMessage(false);
    e.preventDefault();
    if (input.trim()) {
      const userMessage = { sender: "User", message: input };
      setMessages([...messages, userMessage]);

      // Create message object
      let messageToSend;
      if (isFirstMessage) {
        const reqNames = selectedApi;
        messageToSend = { signature: true, req: reqNames, input: null }; // Include signature and req for the first message
        setIsFirstMessage(false); // Update to indicate first message has been sent
      } else {
        messageToSend = { input: input }; // Only include input for subsequent messages
      }

      if (socket) {
        socket.emit('message', messageToSend); // Send the message object
      } else {
        // console.log("Socket not connected");
      }
      setInput("");
    }
  };

  useEffect(() => {
    if (messages.includes("API processing complete") || messages.includes("Nothing else is possible")) {
      // remove the first element from the array
      const tempApis = allApis;
      tempApis.shift();
      setAllApis(tempApis);
    }
  }, [messages]);

  //scroll to bottom of chat box
  useEffect(() => {
    const chatBox = document.querySelector(".chat-box");
    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    // dispatch(requestNames("e7b769ef-b96d-4135-9a4c-ec9b489a639b"));
    dispatch(getAllAPIs());
  }, []);

  useEffect(() => {
    if (apis) {
      const tempApis = [];
      apis?.allApis?.apiNames?.forEach(api => {
        if ((api.source.request.method !== "GET") && (api.source.request.method !== "DELETE") && (api.source.sample_updated !== true)) {
          tempApis.push({ api });
        }
      });
      setAllApis(tempApis);

      if (apis?.allApis && tempApis.length === 0) {
        setChatLoading(true);
        setTimeout(() => {
          setChatLoading(false);
          setPageStatus({ keyIntegrated: false, apiCollected: false, verificationCompleted: true });
        }, 2000);
      }
    }
  }, [apis]);

  
  const handleLogout = async () => {
    await logout();
  }

  //loading||userLoading? <Loader/>:
  return (chatLoading ? <Loader type="Verifying APIs" /> : status === "auth" ? <div className="bg-[#080808] flex justify-center w-full relative overflow-hidden no-scrollbar p-24 py-12">
    <AuthModel allApis={allApis} setCurrentApi={setCurrentApi} addedApis={addedApis} tokenOpen={tokenOpen} setTokenOpen={setTokenOpen} setStatus={setStatus} setExpiryOpen={setExpiryOpen} expiryOpen={expiryOpen} />
    <div className="absolute bottom-0 right-4 flex gap-2">
      <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-0.5 cursor-pointer">Need Help?</div>
      <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-0.5 cursor-pointer" onClick={handleLogout}>Logout</div>
    </div>
  </div> : status === "chat" ? 
    <ChatUI handleLogout ={handleLogout} messages={messages} sendMessage={sendMessage} allApis={allApis} setPageStatus={setPageStatus} input={input} setInput={setInput} enableMessage={enableMessage} setMessages={setMessages} setAllApis={setAllApis} setStatus={setStatus} setIsFirstMessage={setIsFirstMessage} />
    // <ChatUI { handleLogout, messages, sendMessage, allApis, setPageStatus, input, setInput, enableMessage, setMessages, setAllApis, setStatus, setIsFirstMessage } />
   : <div className="bg-[#080808] flex flex-col justify-center gap-4 h-[80vh] text-xl items-center w-full relative overflow-x-hidden p-24 relative">
    <div className="absolute bottom-0 right-4 flex gap-2 text-base">
      <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-0.5 cursor-pointer">Need Help?</div>
      <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-0.5 cursor-pointer" onClick={handleLogout}>Logout</div>
    </div>
    <IoCheckmarkCircleOutline className="text-[#4ADE80]" size={44} />
    All the APIs that we collected have been verified successfully
    <div className="cursor-pointer bg-[#D9509B] px-4 py-1.5 max-w-fit rounded-md border border-[#FF86BE] hover:bg-[#CC3F8D] mt-4 text-base" onClick={() => setPageStatus({ keyIntegrated: false, apiCollected: false, verificationCompleted: true })}>Proceed to Swagger Document</div>
  </div>
  );
};

export default ChatBot;
