import Left from "../../../icons/chevron-left.svg";
import Right from "../../../icons/chevron-right.svg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addBaseURL, downloadSwaggerDoc, getAllAPIs, triggerE2E } from "../../../Actions/tcActions";
import { Dialog, Alert, Typography, Snackbar, Button } from "@mui/material";
import { PiPlus, PiCopy } from "react-icons/pi";
import JSONPretty from "react-json-pretty";
import CopyIcon from "../../../icons/copy-icon.svg";
import { useAuth } from "../../../AuthContext";
import { useTranslation } from "react-i18next";
import { LuTrash } from "react-icons/lu";

const SwaggerDoc = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const apis = useSelector((state) => state.apis);
  const [allApis, setAllApis] = useState(null);
  const [baseUrl, setBaseUrl] = useState("");
  const [runModalOpen, setRunModalOpen] = useState(false);
  const [apiModalOpen, setApiModalOpen] = useState(false);
  const [selectedApi, setSelectedApi] = useState(null);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [urlCount, setUrlCount] = useState(4)
  const [baseUrls, setBaseUrls] = useState(() => {
    const urls = []
    for (let i = 0; i < urlCount; i++) {
      urls.push({ source: "", baseUrl: "" })
    }
    return urls
  })
  const [selected, setSelected] = useState(0);

  const {
    loading: apiLoading,
    swaggerDoc
  } = useSelector((state) => state.apis);

  const { user, apikey, logout } = useAuth();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const data = [];
  const getMethodClasses = (method) => {
    switch (method?.toLowerCase()) {
      case "post":
        return "bg-[#816F33]/40 text-[#FCD34D]";
      case "get":
        return "bg-[#31744A]/40 text-[#4ADE80]";
      case "put":
        return "bg-[#2F5A83]/40 text-[#44A5FF]";
      case "delete":
        return "bg-[#804343]/40 text-[#F87171]";
      case "patch":
        return "bg-[#6B4FBB4D] text-[#96A7FF]"
    }
  };

  const navigate = useNavigate();

  const key = apikey;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 14;
  const totalPages = Math.ceil(apis?.allApis?.apiNames.length / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const currentApis = apis?.allApis?.apiNames?.slice(
    startIdx,
    startIdx + itemsPerPage
  );

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleRunModalOpen = () => {
    setRunModalOpen(true);
  };

  const handleRunModalClose = () => {
    setRunModalOpen(false);
  };

  const runAllTestsHandler = async (e) => {
    try {
      setLoading(true);
      let formattedBaseUrls = []
      for (let i = 0; i < baseUrls.length; i++) {
        if (baseUrls[i].source !== '' && baseUrls[i].baseUrl !== '') {
          formattedBaseUrls.push(baseUrls[i])
        }
      }
      await dispatch({ type: "onboardingComplete" });
      await dispatch(triggerE2E(baseUrls[selected].baseUrl, key));
      await dispatch(addBaseURL(formattedBaseUrls));
      localStorage.setItem('flag', true);
      setLoading(false);
      navigate("/test-suite");
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    // dispatch(requestNames("e7b769ef-b96d-4135-9a4c-ec9b489a639b"));
    dispatch(getAllAPIs());

  }, []);

  useEffect(() => {
    if (apis) {
      // setAllApis(apis.data);
      setAllApis(apis?.allApis?.apiNames);
    }
  }, []);

  // console.log("allAPIs", allApis);

  const isValidBaseUrl = (url) => {
    try {
      const parsedUrl = new URL(url);

      // Ensure that the URL includes a protocol and hostname
      return (
        ["http:", "https:"].includes(parsedUrl.protocol) && !!parsedUrl.hostname
      );
    } catch (e) {
      // console.log("error", e);
      return false; // Invalid URL
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const handleLogout = async () => {
    await logout();
  }

  // console.log(swaggerDoc)

  const handleUrlDelete = (ind) => {
    if (urlCount > 1) {
      baseUrls.splice(ind, 1)
      setBaseUrls([...baseUrls])
      setUrlCount(urlCount - 1)
    }
  }

  const handleCheck = (i) => {
    if (selected == i) setSelected(null)
    else if (baseUrls[i].name !== '' && baseUrls[i].url !== '') {
      setSelected(i)
    } else {
      setAlertMessage("Please fill the details for the selected API")
      setAlertSeverity("error")
      setSnackbarOpen(true)
    }
  }

  const renderBaseUrls = () => {
    let urls = []
    for (let i = 0; i < urlCount; i++) {
      urls.push(<div className="border-b border-dashboard-border-500 grid grid-cols-10">
        <div className="col-span-1 flex items-center justify-center"><input
          // style={{ marginRight: "5px" }}
          type="checkbox"
          id="apiSelected"
          name="apiSelected"
          onClick={() => handleCheck(i)}
          checked={selected == i ? true : false}
        /></div>
        <input value={baseUrls[i].source} onChange={(event) => {
          baseUrls[i].source = event.target.value;
          setBaseUrls([...baseUrls])
        }} className="col-span-4 p-2.5 border-x border-dashboard-border-500 bg-inherit focus:outline-none active:outline-none" placeholder="Service Name" />
        <input value={baseUrls[i].baseUrl} onChange={(event) => {
          baseUrls[i].baseUrl = event.target.value;
          setBaseUrls([...baseUrls])
        }} className="col-span-4 p-2.5 border-r border-dashboard-border-500 bg-inherit focus:outline-none active:outline-none" placeholder="https://example.com" />
        <div className="col-span-1 text-[#494949] flex items-center justify-center" >
          <span onClick={() => handleUrlDelete(i)} className={urlCount > 1 ? "cursor-pointer rounded-md p-1.5 hover:text-[#F87171] hover:bg-[#1D1E20]" : ""}>
            <LuTrash />
          </span>
        </div>
      </div>)
    }
    return urls
  }

  return (<div className="flex flex-col overflow-x-hidden no-scrollbar">
    <div className="px-24 pt-10 flex flex-col">
      <div className="flex justify-between">
        <div className="text-[#F37BB3] text-xl font-semibold">
          Swagger Document
        </div>
        <div className="flex gap-2">
          <div className="text-[#E7E7E7] bg-[#D9509B] hover:bg-[#CC3F8D] border border-1 border-[#FF86BE] rounded-md min-w-fit px-4 py-1 cursor-pointer" onClick={() => dispatch(downloadSwaggerDoc())}>Download</div>
          <div onClick={handleLogout} className="text-[#E7E7E7] border border-dashboard-border-500 rounded min-w-fit py-1 px-4 cursor-pointer">{t('actions.logout')}</div>
        </div>
      </div>
      <div className="grid grid-rows-7 grid-cols-2 gap-x-4 gap-y-3 mt-8">
        {currentApis?.map((api) => (
          <div className="bg-[#0F1011] border border-1 border-[#2E3138] rounded-md flex px-4 py-2.5 items-center">
            <div
              className={
                "mr-4 rounded-md min-w-[68px] text-center py-1.5 px-2 text-caption font-semibold uppercase " +
                getMethodClasses(api.source.request.method)
              }
            >
              {api.source.request.method}
            </div>
            <div className="flex justify-between gap-2 w-[85%] max-w-[85%] relative items-center">
              <div className="relative w-[75%] max-h-[40px] max-w-[75%] overflow-y-scroll no-scrollbar font-semibold">
                <div>{api.name}</div>
                <div className="text-[10px] break-words text-wrap mt-1 text-[#B0B0B0]">
                  {String(api.source.request.path)}
                </div>
              </div>
              <div
                onClick={() => {
                  setApiModalOpen(true);
                  setSelectedApi(api);
                }}
                className="bg-[#16171A] hover:bg-[#1E1F23] min-[100px] text-center border border-1 border-[#43454A] px-3 py-1 rounded text-sm cursor-pointer"
              >
                view more
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-between items-center w-full mt-4">
        <div className="flex items-center self-end">
          <div className="flex text-[#EFEFEF] mr-2">
            <div
              className="rounded-md flex items-center border border-1 cursor-pointer border-[#2C2E33] text-[#B0B0B0] text-sm mr-3 px-3 py-1"
              onClick={handlePrevPage}
            >
              <img src={Left} className="mr-2" /> Prev
            </div>
            <div
              className={
                "grid gap-2 mr-3 " +
                (startIdx + itemsPerPage >= apis?.allApis?.apiNames?.length
                  ? "grid-cols-2"
                  : "grid-cols-3")
              }
            >
              {startIdx + itemsPerPage >= apis?.allApis?.apiNames?.length ? (
                <>
                  <div onClick={() => setCurrentPage(currentPage - 1)} className="cursor-pointer border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage - 1}
                  </div>
                  <div className="cursor-pointer border border-1 border-[#F257A0] p-1 w-[34px] bg-[#F257A0] text-center rounded-md">
                    {currentPage}
                  </div>
                </>
              ) : currentPage > 1 ? (
                <>
                  <div onClick={() => setCurrentPage(currentPage - 1)} className="cursor-pointer border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage - 1}
                  </div>
                  <div className="cursor-pointer border border-1 border-[#F257A0] p-1 w-[34px] bg-[#F257A0] text-center rounded-md">
                    {currentPage}
                  </div>
                  <div onClick={() => setCurrentPage(currentPage + 1)} className="cursor-pointer border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage + 1}
                  </div>
                </>
              ) : (
                <>
                  <div className="cursor-pointer bg-[#F257A0] p-1 w-[34px] text-center rounded-md">
                    {currentPage}
                  </div>
                  {startIdx + itemsPerPage <= apis?.allApis?.apiNames?.length ? <div onClick={() => setCurrentPage(currentPage + 1)} className="cursor-pointer border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage + 1}
                  </div> : null}
                  {startIdx + itemsPerPage <= apis?.allApis?.apiNames?.length ? <div onClick={() => setCurrentPage(currentPage + 2)} className="cursor-pointer border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage + 2}
                  </div> : null}
                </>
              )}
            </div>
            <div
              className="rounded-md flex items-center border border-1 cursor-pointer border-[#2C2E33] text-sm px-3 py-1 mr-4"
              onClick={handleNextPage}
            >
              Next <img className="ml-2" src={Right} />
            </div>
          </div>
          <div className="text-[#EFEFEF] text-sm font-light">
            {" "}
            {startIdx + 1 === apis?.allApis?.apiNames?.length
              ? startIdx + 1
              : startIdx + itemsPerPage > apis?.allApis?.apiNames?.length
                ? apis?.allApis?.apiNames?.length
                : startIdx + 1 + "-" + (startIdx + itemsPerPage)}{" "}
            of {apis?.allApis?.apiNames?.length} Results
          </div>
        </div>
        <div>
          Number of APIs captured{" "}
          <span className="ml-3 px-3 py-[3px] bg-dashboard-dark-100 border border-dashboard-border-500 rounded text-paragraph">
            {apis?.allApis?.apiNames.length}
          </span>
        </div>
      </div>
      <div
        onClick={handleRunModalOpen}
        className="cursor-pointer w-1/3 py-2.5 mt-8 rounded-md bg-[#D9509B] hover:bg-[#CC3F8D] border border-1 border-[#FF86BE] text-center self-center"
      >
        {t("actions.proceed_to_analysis")}
      </div>
      <Dialog
        open={runModalOpen}
        onClose={handleRunModalClose}
        maxWidth={"75vw"}
        PaperProps={{
          style: {
            width: "60vw",
            backgroundColor: "#17171A",
            color: "#fff",
            boxShadow: "none",
            borderRadius: "8px",
            border: "1px solid #2C2E33",
            overflow: "hidden",
          },
        }}
      >
        <div className="flex flex-col">
          <div>
            <div
              onClick={handleRunModalClose}
              className="text-white cursor-pointer mt-8 mr-8 justify-self-end"
            >
              <PiPlus className="rotate-45" size={18} />
            </div>
            <div className="p-10 pt-4">
              <div className="text-xl text-[#D9D9D9]">Version</div>
              <div className="rounded-md p-3 border border-[#2C2E33] flex justify-between items-center w-1/2 mt-4 mb-8">
                <span>Version-1.2.5</span>
                <PiCopy className="text-gray-400 cursor-pointer" />
              </div>
              <div className="text-xl text-[#D9D9D9] mb-4">Add Base URL</div>
              <div className="rounded-md text-dashboard-text-gray border border-dashboard-border-500">
                <div className="bg-dashboard-dark-600  border-b border-dashboard-border-500 grid grid-cols-10">
                  <div className="col-span-1" />
                  <div className="col-span-4 p-2.5 border-x border-dashboard-border-500" >Microservice Name</div>
                  <div className="col-span-4 p-2.5 border-r border-dashboard-border-500" >URL</div>
                  <div className="col-span-1" />
                </div>
                {renderBaseUrls()}
              </div>
            </div>
            <div className="flex justify-end gap-2 px-12 py-4 border-t border-dashboard-border-500 items-center">
              <Button
                sx={{
                  borderRadius: "4px",
                  backgroundColor: "#141516",
                  border: "1px solid #35383E",
                  fontSize: "12px",
                  padding: "8px 20px",
                  ":hover": {
                    backgroundColor: "#CC3F8D",
                  },
                  color: "white",
                  textTransform: "unset",
                  fontWeight: "400",
                }}
                onClick={handleRunModalClose}
                className="cursor-pointer rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] px-4 py-1.5"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="loginButton"
                sx={{
                  padding: "8px 20px",
                  fontSize: "12px",
                  fontFamily: "Roboto",
                  borderRadius: "6px",
                  border: "1px solid #E27AAB",
                  backgroundColor: "#E27AAB",
                  ":hover": {
                    backgroundColor: "#DD6B9F",
                    borderColor: "#FA8DC0",
                  },
                  textTransform: "unset",
                }}
                onCanPlay={runAllTestsHandler}
              >
                Confirm & Run Test Cases
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        maxWidth="85vw"
        open={apiModalOpen}
        onClose={() => setApiModalOpen(false)}
        PaperProps={{
          style: {
            minHeight: "fit",
            width: "75vw",
            backgroundColor: "#17171A",
            color: "#fff",
            boxShadow: "none",
            borderRadius: "12px",
            border: "1px solid #2C2E33",
          },
        }}
      >
        <div className="p-12 pb-8 w-full flex flex-col gap-2">
          <div
            onClick={() => setApiModalOpen(false)}
            className="rotate-45 cursor-pointer text-[#B0B0B0] absolute top-4 right-4"
          >
            <PiPlus size={20} />
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div
                className={
                  "mr-4 rounded-md min-w-[80px] text-center py-1.5 text-sm font-semibold " +
                  getMethodClasses(selectedApi?.source?.request?.method)
                }
              >
                {selectedApi?.source.request.method}
              </div>
              <div className="font-semibold">{selectedApi?.name}</div>
            </div>
            <div className="rounded-md border border-1 bg-[#0F1011] text-nowrap border-[#2C2E33] px-3 py-1.5 min-w-[10vw] max-w-[30vw] overflow-x-scroll no-scrollbar text-sm">
              {selectedApi?.source.request.path}
            </div>
          </div>
          {selectedApi?.source?.request?.headers ? (
            <div className="mt-4">
              <div className="font-semibold">Headers</div>
              <div className="text-sm font-light border border-1 border-[#2C2E33] rounded-md max-h-[40vh] mt-2 overflow-y-scroll no-scrollbar">
                {Object.entries(selectedApi?.source?.request?.headers).map(
                  ([key, value]) => (
                    <div className="grid grid-cols-6">
                      <div className="col-span-2 px-4 py-2 bg-[#0F1011] border-b border-r border-[#2C2E33]">
                        {key}
                      </div>
                      <div className="col-span-4 overflow-x-scroll no-scrollbar whitespace-normal px-4 py-2 bg-[#0F1011] border-b border-[#2C2E33]">
                        {String(value)}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
          <div className="mt-4 grid grid-cols-2 gap-4">
            <div className="border border-1 border-[#2C2E33] max-w-[40vw] rounded-md">
              <div className="bg-[#17181A] border-b border-[#2C2E33] p-3 flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <div className="border border-[#533543] rounded-md px-3 py-0.5">1</div>
                  <select name="body1" id="body1" className="border border-[#533543] bg-inherit rounded-md min-w-[200px] px-3 py-1">
                    <option value="body1">Request Body 1</option>
                  </select>
                </div>
                <img
                  onClick={() =>
                    copyToClipboard(
                      JSON.stringify(selectedApi?.source?.request?.body ?? {})
                    )
                  }
                  className="w-[16px] h-[16px] cursor-pointer"
                  src={CopyIcon}
                  alt="copy icon"
                />
              </div>
              <div className="h-[30vh] max-h-[30vh] px-3 py-3 bg-[#0F1011] text-[#B0B0B0]">
                <JSONPretty
                  style={{ overflowY: "scroll", height: "100%" }}
                  id="json-pretty"
                  themeClassName="text-sm"
                  keyStyle="color: #E45799;"
                  data={JSON.stringify(
                    selectedApi?.source?.request?.body ?? {}
                  )}
                ></JSONPretty>
              </div>
            </div>
            <div className="border border-1 border-[#2C2E33] max-w-[40vw] rounded-md">
              <div className="bg-[#17181A] border-b border-[#2C2E33] px-3 py-4 flex justify-between items-center">
                Response{" "}
                <img
                  onClick={() =>
                    copyToClipboard(
                      JSON.stringify(
                        selectedApi?.source?.response?.content ?? {}
                      )
                    )
                  }
                  className="w-[16px] h-[16px] cursor-pointer"
                  src={CopyIcon}
                  alt="copy icon"
                />
              </div>
              <div className="h-[30vh] max-h-[30vh] p-3 bg-[#0F1011] text-[#B0B0B0] relative">
                <JSONPretty
                  style={{ overflowY: "scroll", height: "100%", paddingTop: "1rem" }}
                  id="json-pretty"
                  themeClassName="text-sm"
                  keyStyle="color: #E45799;"
                  data={JSON.stringify(
                    selectedApi?.source?.response?.content ?? {}
                  )}
                ></JSONPretty>
                <div className={"absolute top-3 right-3 rounded-md max-h-fit py-0.5 px-3 text-white " + (selectedApi?.source?.response?.status_code < (300) ? "bg-[#31744A]" : "bg-[#633238]")}>{selectedApi?.source?.response?.status_code}</div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  </div>
  );
};

export default SwaggerDoc;
