import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Function to load all translation files
const loadTranslations = () => {
  const context = require.context('../locales', true, /\.json$/);
  const translations = {};

  context.keys().forEach((key) => {
    const match = key.match(/\/([a-z]{2})\.json$/i);
    if (match) {
      const language = match[1];
      translations[language] = {
        translation: context(key)
      };
    }
  });

  return translations;
};

i18n
  .use(initReactI18next)
  .init({
    resources: loadTranslations(),
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false // React already escapes values
    }
  });

export default i18n;