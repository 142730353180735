import { useState } from 'react';
import { DevzeryLogo, Globe, SwaggerIcon, SettingsIcon, LogOutIcon } from "../../icons";
import '../Navbar/Navbar.css'
import { useNavigate } from 'react-router-dom';

export const Header = ({ workspaces, projects, username, logout }) => {

  const [selectedWorkspace, setSelectedWorkspace] = useState(workspaces[0]);
  const [selectedProject, setSelectedProject] = useState(projects[0]);
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    await logout();
    navigate('/login');
  };

  return (
    <header className="bg-black px-8 py-4 flex-shrink-0 border-b border-[#2C2E33]">
      <div className="flex items-center justify-between">
        {/* Logo and Workspace */}
        <div className="flex items-center gap-4">
          <div className=""><img src={DevzeryLogo} alt="" /></div>
          {/* Workspace Dropdown */}
          <select
            value={selectedWorkspace}
            onChange={(e) => setSelectedWorkspace(e.target.value)}
            className="bg-black text-gray-400 rounded-md px-2 py-1 text-sm border border-gray-700 h-8"
            disabled={workspaces.length <= 1}
          >
            {workspaces.map((workspace, index) => (
              <option key={index} value={workspace}>
                {workspace}
              </option>
            ))}
          </select>
          {/* Project Dropdown */}
          <select
            value={selectedProject}
            onChange={(e) => setSelectedProject(e.target.value)}
            className="bg-black text-gray-400 rounded-md px-2 py-1 text-sm border border-gray-700 h-8"
            disabled={projects.length <= 1}
          >
            {projects.map((project, index) => (
              <option key={index} value={project}>
                {project}
              </option>
            ))}
          </select>
        </div>
        {/* User Info and Icons */}
        <div className="flex items-center gap-4 text-gray-400">
          <Globe className="text-gray-400 w-5 h-5 cursor-pointer" onClick={() => navigate('/base-url')} />
          <SwaggerIcon className="text-gray-400 w-5 h-5 cursor-pointer" />
          <SettingsIcon className="text-gray-400 w-5 h-5 cursor-pointer" onClick={() => navigate('/settings')} />
          <div className="flex items-center justify-between gap-4 text-gray-400 rounded-md px-2 text-sm border border-gray-700 h-8">
            <span>{username}</span>
            <LogOutIcon
              className="h-4 w-4 cursor-pointer"
              onClick={handleLogout}
              role="button"
              title="Logout"
            />
          </div>
        </div>
      </div>
    </header>
  );
};