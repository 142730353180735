import { Panel } from "react-resizable-panels";
import { LuTrash } from "react-icons/lu";
import { useAuth } from "../../../AuthContext";


const SettingsHeading = ({
    children,
    variant = 'workspace',

}) => {
    const { user } = useAuth();

    const variants = {
        workspace: {
            title: 'Workspace Settings',
            description: 'Create a workspace that works for you by managing settings, adjusting preferences, and ensuring your team has the tools they need to succeed.',
        },
        profile: {
            title: "Profile",
            description: "Manage your Devzery profile"
        },
        team: {
            title: "Team Members",
            description: "Manage your team members"
        },
        notifications: {
            title: "Notification Settings",
            description: "Updates will be sent to the below email address"
        },
        support: {
            title: "Customer Support",
            description: "Get the help you need with our responsive customer support. Whether you have questions, need guidance, or are facing an issue, our team is here to assist you. Reach out anytime, and we'll work to resolve your concerns quickly and efficiently."
        }
    }

    return (
        <div  className="pb-16 mb-16 border-b border-[#282C33]">
            <Panel>
                <div>
                    <div className="flex justify-between">
                        <div className="flex items-center bg-background mb-4 gap-3">
                            {variant === 'profile' && (
                                <div className="flex justify-center items-center w-[50px] h-[50px] rounded-full bg-[#E27AAB] uppercase">
                                    {user.name.split(" ").map((name) => name[0]).join("")}
                                </div>
                            )}
                            <p className="text-2xl ">
                                {variants[variant]?.title}
                            </p>
                        </div>
                        {variant === 'workspace' ?
                            <div className="mt-6">
                                <div
                                    className="rounded-md bg-[#141516] border border-[#282C33] flex gap-2 items-center py-2 px-3 text-[#B0B0B0]"
                                >
                                    <LuTrash />
                                    Delete Workspace
                                </div>
                            </div> : null
                        }
                    </div>
                    <div className="flex bg-background w-[480px]">
                        <p className="text-[#B0B0B0]">
                            {variants[variant]?.description}
                        </p>
                    </div>
                </div>
                {children}
            </Panel>
        </div>
    );
}

export default SettingsHeading;