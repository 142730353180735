import { memo } from 'react';
import { NumberIcon, CalendarIcon, ClockIcon, DurationIcon, PriorityIcon } from "../../icons";
import { Link, useNavigate } from 'react-router-dom';

const getStatusStyles = (status) => {
  const styles = {
    Open: {
      container: "bg-[#261322] text-white",
      dot: "bg-[#E27AAB]"
    },
    Closed: {
      container: "bg-[#0B1C2B] text-white",
      dot: "bg-[#44A5FF]"
    },
    default: {
      container: "bg-dashboard-dark-600 text-dashboard-text-gray",
      dot: "bg-dashboard-text-gray"
    }
  };
  
  return styles[status] || styles.default;
};

const StatusBadge = memo(({ status }) => {
  const styles = getStatusStyles(status);
  
  return (
    <span className={`px-2 py-1.5 rounded-md text-sm ${styles.container}`}>
      <span className={`inline-block w-2.5 h-2.5 rounded-full mr-2 ${styles.dot}`}></span>
      {status}
    </span>
  );
});

const InfoBadge = memo(({ icon: Icon, value }) => (
  <div className="inline-flex items-center space-x-2 px-2 py-1.5 rounded-md border border-dashboard-dark-400">
    <Icon className="h-5 w-5" />
    <span>{value}</span>
  </div>
));

const TestCard = ({ 
    index,  
  test
}) => {
    const navigate = useNavigate();

  const {
    _id,
    tcNumber,
    pass: rawStatus,
    name,
    date,
    time,
    duration,
    priority = "Medium" // Default priority
  } = test || {};

   // Convert boolean status to string
   const status = typeof rawStatus === 'boolean' 
   ? (rawStatus ? "" : "Open")
   : rawStatus;

  const handleCardClick = (e) => {    
    if (_id) {
      navigate(`/bug/${_id}`);
    }
  };

  return (
    <div key={index} className="mb-3 bg-dashboard-dark-700 rounded-md p-2 border border-dashboard-dark-500 cursor-pointer"
      onClick={handleCardClick}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="inline-flex items-center px-2 py-1.5 rounded-md border border-dashboard-dark-500 bg-dashboard-dark-600 text-sm text-dashboard-text-white">
            <NumberIcon className="h-4 w-4" />
            <span>{tcNumber}</span>
          </div>
          <StatusBadge status={status} />
        </div>
      </div>

      <p className="mt-2 text-dashboard-text-white">{name}</p>

      <div className="mt-4 flex items-center space-x-2 text-sm text-dashboard-text-gray">
        <InfoBadge icon={CalendarIcon} value={date} />
        <InfoBadge icon={ClockIcon} value={time} />
        <InfoBadge icon={DurationIcon} value={duration/1000} />
        <InfoBadge 
          icon={() => <PriorityIcon priority={priority} />} 
          value={priority} 
        />
      </div>
    </div>
  );
};

// Only re-render if props change
export default memo(TestCard);