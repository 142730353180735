import SDKCard from './SDKCard/SDKCard';
import { Alert, Snackbar } from '@mui/material';
import { PiCopy } from "react-icons/pi";
import nodeIcon from '../../../icons/node-icon.svg'
import pythonIcon from '../../../icons/python-icon.svg'
import javaIcon from '../../../icons/java-icon.svg'
import golangIcon from '../../../icons/golang-icon.svg'
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getAPICount } from '../../../Actions/tcActions';
import {CircularProgress} from '@mui/material';
import { useAuth } from '../../../AuthContext';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';


const SDK = ({setPageStatus}) =>{

    const {t} = useTranslation();

    const [noKey, setNoKey] = useState(false)

    const dispatch = useDispatch();

    const { apikey, logout } = useAuth();
    const {
        loading: apiLoading,
        error,
        userApiCount
    } = useSelector((state) => state.apis);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setSnackbarOpen(false);
      };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(apikey);
        setSnackbarOpen(true);
        setAlertMessage("Copied to clipboard");
        setAlertSeverity("success");
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 1000);
    };

    const handleProceed = () => {
        setPageStatus({keyIntegrated: true, apiCollected: false, verificationCompleted: false});
    }

    useEffect(() => {
        dispatch(getAPICount())
    }, [])

    useEffect(() => {
        if (error) {
            setAlertSeverity("error");
            setAlertMessage(error);
            setSnackbarOpen(true);
            dispatch({type: 'clearError'})
        }
    }, [error])

    const renderComponent = () => {
        setTimeout(() => {
            setNoKey(true)
        }, 4000);
        return noKey ? <div onClick={() => {dispatch(getAPICount()); setNoKey(false); setTimeout(() => {
            setNoKey(true)
        }, 4000);}} className="cursor-pointer w-1/4 py-3 mt-16 rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] text-center">{t('actions.refresh_to_update_integration_status')}</div> : <div className="mt-16 flex items-center"><span className='text-[#D9509B] flex items-center mr-3'><CircularProgress size={20} color="inherit" /></span>Waiting for the API key to be added into your project</div>
    }

    const handleLogout = async () => {
        await logout();
    }


    return  <div className="px-16 py-10 relative overflow-hidden">
        <div className='flex gap-2 absolute bottom-4 right-4'>
            <div className="text-[#E7E7E7] bg-[#26282F] border border-[#2C2E33] rounded min-w-fit px-4 py-1 cursor-pointer"onClick={handleLogout}>{t('actions.logout')}</div>
            <div className="text-[#E7E7E7] bg-[#26282F] border border-[#2C2E33] rounded min-w-fit px-4 py-1">{t('actions.need_help')}</div>
        </div>
    <div className="w-full flex flex-col items-center justify-between">
        <div className="text-[#F37BB3] text-h2 font-semibold">{t('headings.integrate_sdk')}</div>
        <div className="mt-24">{t('headings.get_started_apikey')}</div>
        <div className="rounded-md border border-dashboard-border-600 px-4 py-2.5 mt-3 w-1/2 flex justify-between items-center text-dashboard-text-graylight">
            {apikey}
            <span data-tooltip-content="Copy" data-tooltip-place="top" data-tooltip-id="copy">
                <PiCopy size={20} className='cursor-pointer hover:text-[#D9509B]' onClick={copyToClipboard} />
                <Tooltip id='copy' />
            </span>
        </div>
        <div className="mt-20">Select required SDK</div>
        <div className="grid grid-cols-2 gap-3 mt-4">
            <SDKCard lang={t('languages.nodejs')} image={nodeIcon} githubUrl="https://github.com/devzery/devzery_middleware_express"/>
            <SDKCard lang={t('languages.python')} image={pythonIcon} githubUrl="https://github.com/devzery/devzery_middleware"/>
            <div className="justify-self-center self-center translate-x-1/2">
                <SDKCard lang={t('languages.java')} image={javaIcon} githubUrl="https://github.com/devzery/devzery-middleware-springboot"/>
            </div>
            {/* <SDKCard lang="golang" image={golangIcon} docsUrl="https://github.com/devzery/devzery_middleware" githubUrl="https://github.com/devzery/devzery_middleware"/> */}
        </div>
        {userApiCount?.data?.count > 0 ? 
        <div onClick={handleProceed} className="cursor-pointer w-1/3 py-2.5 mt-16 bg-gradient-primary hover:bg-gradient-primary-active rounded border border-[#FF86BE] text-center">{t('actions.proceed_to_analysis')}</div> 
        : renderComponent()}
    </div>
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
</div>
}

export default SDK