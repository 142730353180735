import { memo } from 'react';
import { NumberIcon, CalendarIcon, ClockIcon, DurationIcon, PriorityIcon } from "../../icons";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { IoMdPlay } from "react-icons/io";
import { LuTrash } from "react-icons/lu";


const getStatusStyles = (status) => {
  const styles = {
    Passed: {
      container: "bg-dashboard-dark-201 text-dashboard-text-green",
      dot: "bg-dashboard-dark-203"
    },
    Failed: {
      container: "bg-dashboard-dark-200 text-dashboard-text-red",
      dot: "bg-dashboard-dark-202"
    },
    default: {
      container: "bg-dashboard-dark-600 text-dashboard-text-gray",
      dot: "bg-dashboard-text-gray"
    }
  };


  return styles[status] || styles.default;
};


const StatusBadge = memo(({ status }) => {
  const styles = getStatusStyles(status);


  return (
    <span className={`px-2 py-1.5 rounded-md text-sm ${styles.container}`}>
      <span className={`inline-block w-2.5 h-2.5 rounded-full mr-2 ${styles.dot}`}></span>
      {status}
    </span>
  );
});


const InfoBadge = memo(({ icon: Icon, value }) => (
  <div className="inline-flex items-center space-x-2 px-2 py-1.5 rounded-md border border-dashboard-dark-400">
    <Icon className="h-5 w-5" />
    <span>{value}</span>
  </div>
));


const TestCard = ({
  index,
  test,
  onRerun,
  selectedTests,
  setSelectedTests,
  deleteTest
}) => {
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);


  const {
    _id,
    tcNumber,
    pass: rawStatus,
    name,
    date,
    time,
    duration,
    description,
    priority = "Medium" // Default priority
  } = test || {};


  // Convert boolean status to string
  const status = typeof rawStatus === 'boolean'
    ? (rawStatus ? "Passed" : "Failed")
    : rawStatus;


  // console.log("Status", status);


  const handleCardClick = (e) => {
    // Prevent navigation if clicking on the rerun button
    if (e.target.closest('.rerun-button')) {
      return;
    }


    if (_id) {
      navigate(`/test/${_id}`);
    }
  };


  const handleTestSelect = () => {
    if (selectedTests.includes(name)) {
      setSelectedTests(selectedTests.filter((test) => test !== name));
    } else {
      setSelectedTests([...selectedTests, name]);
    }
    setSelected(!selected);
    // console.log(selectedTests);
  }


  return (
    <div key={index} className="mb-3 bg-dashboard-dark-700 rounded-md p-2 border border-dashboard-dark-500 cursor-pointer flex"
    >
      <div className="mt-2 mr-3"><input
        // style={{ marginRight: "5px" }}
        type="checkbox"
        id="apiSelected"
        name="apiSelected"
        checked={selected}
        onClick={handleTestSelect}
      /></div>
      <div className='w-full' onClick={handleCardClick}>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div className="inline-flex items-center px-2 py-1.5 rounded-md border border-dashboard-dark-500 bg-dashboard-dark-600 text-sm text-dashboard-text-white">
              <NumberIcon className="h-4 w-4" />
              <span>{tcNumber}</span>
            </div>
            <StatusBadge status={status} />
          </div>
        </div>
        <p className="mt-2 text-dashboard-text-white">{description ?? name}</p>
        <div className="mt-4 flex items-center space-x-2 text-sm text-dashboard-text-gray">
          <InfoBadge icon={CalendarIcon} value={date} />
          <InfoBadge icon={ClockIcon} value={time} />
          <InfoBadge icon={DurationIcon} value={duration / 1000} />
          <InfoBadge
            icon={() => <PriorityIcon priority={priority} />}
            value={priority}
          />
        </div>
      </div>
      <div className="relative cursor-pointer z-10">
        <IoEllipsisHorizontalSharp onClick={() => setOpenMenu(!openMenu)} size={20} />
        {openMenu && <div className='bg-[#141516] rounded-lg border border-dashboard-dark-400 p-2 absolute top-5 right-1'>
          <div className='px-4 py-1.5 rounded-xl flex gap-2 items-center text-[#B0B0B0] hover:text-white hover:bg-dashboard-dark-600' onClick={() => {
            setOpenMenu(!openMenu);
            onRerun(tcNumber, name);
          }}>
            <span className='text-[#494949] hover:text-white'>
              <IoMdPlay />
            </span>
            Run</div>
          <div className='px-4 py-1.5 rounded-xl flex gap-2 items-center text-[#B0B0B0] hover:text-white hover:bg-dashboard-dark-600' onClick={() => {
            setOpenMenu(!openMenu);
            deleteTest()
            }}>
            <span className='text-[#494949] hover:text-white'>
              <LuTrash />
            </span>
            Delete</div>
        </div>}
        {/* <TestRerun
              testId={tcNumber}
              testStatus={status}
              isRunning={isRunning}
              onRerun={onRerun}
            /> */}
      </div>
    </div>
  );
};


// Only re-render if props change
export default memo(TestCard);