import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Footer } from './Footer';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import Loader from "../Loader/Loader";

const SIDEBAR_WIDTH = 8;
const MAIN_CONTENT_WIDTH = 92;

export const Layout = ({ /* workspaces, projects, username, */ loading, children }) => {
    const { user, logout } = useAuth();
    const workspaces = user?.orgDetails?.workspace ? [user.orgDetails.workspace] : ["My_workspace"];
    const projects = user?.orgDetails?.projects || ["My_project"];
    const username = user?.name || "john_doe";

    return (
        <div className="h-screen w-screen flex flex-col text-gray-100">
            <Header workspaces={workspaces} projects={projects} username={username} logout={logout} />
            <div className="flex-1 flex overflow-hidden">
                <PanelGroup direction="horizontal">
                    {/* Sidebar Panel */}
                    <Panel
                        defaultSize={SIDEBAR_WIDTH}
                        minSize={SIDEBAR_WIDTH}
                        maxSize={SIDEBAR_WIDTH}
                        resize={"false"}
                    >
                        <div className="flex flex-col h-full">
                            {/* Sidebar content */}
                            <Sidebar className="flex-1" />
                            {/* Footer specific to sidebar */}
                            <Footer />
                        </div>
                    </Panel>
                    {/* Main Content Panel */}
                    <Panel
                        defaultSize={MAIN_CONTENT_WIDTH}
                        minSize={MAIN_CONTENT_WIDTH}
                        className="flex-1"
                    >
                        
                        <main className="flex flex-col h-full hide-scrollbar bg-dashboard-dark-800">
                        <Loader loading={loading}>
                            {children}
                            {/* <Outlet /> */}
                            </Loader>
                        </main>
                        
                    </Panel>
                </PanelGroup>
            </div>
        </div>
    );
};

export default Layout;