export const ChartNoAxesColumnIncreasing = ({ priority }) => {
  const getStrokeColor = (priority) => {
    switch (priority) {
      case 'High':
        return ['#F87171', '#F87171', '#F87171']; // All red for High
      case 'Medium':
        return ['#FCD34D', '#494949', '#FCD34D']; // Yellow (1,3), Grey (2) for Medium
      case 'Low':
      default:
        return ['#494949', '#494949', '#44A5FF']; // Grey (1,2), Blue (3) for Low
    }
  };  

  const [col1Color, col2Color, col3Color] = getStrokeColor(priority);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-5 w-5"
    >
      {/* Column 1 */}
      <line
        x1="12"
        x2="12"
        y1="20"
        y2="10"
        stroke={col1Color} // Uses the priority-based color
      />
      
      {/* Column 2 */}
      <line
        x1="18"
        x2="18"
        y1="20"
        y2="4"
        stroke={col2Color} // Uses High color or defaults
      />
      
      {/* Column 3 */}
      <line
        x1="6"
        x2="6"
        y1="20"
        y2="16"
        stroke={col3Color} // Defaults to white for low priority
      />
    </svg>
  );
};