import React, { useState } from "react";
import "./APISidebar.css";
import { Typography } from "@mui/material";
import CaretForward from "../../icons/caret-forward.svg";
import StopOutline from "../../icons/stop-outline.svg";
import PauseIcon from "../../icons/pause-icon.svg";
import IgnoreIcon from "../../icons/ignore.svg";
import DeleteIcon from "../../icons/delete-icon.svg";
import Assertion from "../Assertion/Assertion";
import Dependency from "../Dependency/Dependency";
import BaseUrlModal from "../TestSuite/BaseUrlModal/BaseUrlModal";

/*

APISidebar component that renders the sidebar for the Individual API page.
It uses the useState hook to manage the state of the modal.
It contains buttons to perform actions on the API.
It displays the status of the API and its dependencies.
It also contains a button to view the base URLs of the API.
It displays the priority and status of the API in case of bugs.

*/

const APISidebar = ({ isBugs, api, dependencies }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  return isBugs === false ? (
    <div className="text-white bg-[#21222d] h-[100vh]">
      <div className="sidebar-top">
        <div className="sidebar-top-btns">
          <img
            className="sidebar-icon"
            src={CaretForward}
            alt="caret-forward"
          />
          <img className="sidebar-icon" src={StopOutline} alt="stop-outline" />
          <img className="sidebar-icon" src={PauseIcon} alt="pause-icon" />
          <img className="sidebar-icon" src={IgnoreIcon} alt="ignore-icon" />
        </div>
      </div>
      <div className="sidebar-middle">
        <button className="base-urls-btn" onClick={handleModalOpen}>
          View Base URLs
        </button>
      </div>
      <div className="sidebar-bottom">
        <div className="status-header">
          <Typography>Status</Typography>
        </div>
        <div className={"api-status " + (api?.pass ? "text-[#4ADE80]" : "text-[#E84343]")}>
          <p className={api?.pass ? "text-[#4ADE80]" : "text-[#E84343]"}>{api?.pass ? "Passed" : "Failed"}</p>
        </div>
        <div className="assertion-header">
          <Typography>Dependencies</Typography>
        </div>
        <div className="max-h-[65vh] overflow-y-scroll no-scrollbar">
          {dependencies.length > 0 ? dependencies.sort((a, b) => a.step - b.step).map((dependency, index) => (
            <Dependency title={dependency.title} />
          )): <div className="text-[#6B7280] text-center mt-4">No dependencies found</div>}
        </div>
        {/* {api?.assertions?.err ? <><div className="assertion-header">
          <Typography>Error Details</Typography>
        </div>
        <div className="bg-[#171821] p-3">
            <div className="text-[#E84343] mb-4">{api?.assertions?.err.message.split(":")[0]}</div>
            <div className="capitalize text-[#F87171] text-sm">{api?.assertions?.err.message.split(":")[1]}</div>
        </div></> : null} */}
      </div>
      <BaseUrlModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  ) : (
    <div className="sidebar-container">
      <div className="sidebar-top">
        <div className="sidebar-top-btns">
          <img className="sidebar-icon" src={IgnoreIcon} alt="ignore-icon" />
          <img className="sidebar-icon" src={DeleteIcon} alt="pause-icon" />
        </div>
      </div>
      <div className="sidebar-middle"></div>
      <div className="sidebar-bottom">
        <Typography>Priority</Typography>
        <select className="priority-select">
          <option selected>High</option>
          <option>Medium</option>
          <option>Low</option>
        </select>
        <div className="status-header">
          <Typography>Status</Typography>
        </div>
        <div className="status-select">
          <select>
            <option selected>Open</option>
            <option>Close</option>
          </select>
        </div>
        <div className="assertion-header">
          <Typography>Dependencies</Typography>
        </div>
        <div className="view-rel-tc">
          <Typography sx={{ fontSize: "2vh" }}>
            View Related Test Case
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default APISidebar;
