import React from 'react'
import './CustomButton.css'
import { Typography } from '@mui/material'
import bitBucketIcon from '../../icons/bitbucket-icon.png'
import gitHubIcon from '../../icons/github-icon.svg'
import gitLabIcon from '../../icons/gitlab-icon.png'
import googleIcon from '../../icons/google-icon.svg'

/*

The CustomButton component renders a custom button with an icon and text.
It is used to display Google and GitHub buttons on the Sign In page.

*/

const CustomButton = ({icon,color,sso,height,width,onClick}) => {
    const containerStyle = {
        backgroundColor: color, // Use the color prop for background color
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px 16px',
        borderRadius: '6px',
        cursor: 'pointer',
        color:"white",
      };

    const imageStyle = {
        height: height, // Use the height prop for height
        width: width, // Use the width prop for width
        marginRight: '8px',
      };
    let iconSrc;
    // Set the icon source based on the sso prop
    switch (sso) {
        case 'GitHub':
            iconSrc = gitHubIcon;
            break;
        case 'GitLab':
            iconSrc = gitLabIcon;
            break;
        case 'Bitbucket':
            iconSrc = bitBucketIcon;
            break;
        case 'Google':
            iconSrc = googleIcon;
            break;
    }
    const handleOnClick = () => {
        onClick()
    }
    return (
        <div style={containerStyle} className='customBtnContainer' onClick={handleOnClick}>
            <div className="ssoLogo">
            <img style={imageStyle} src={iconSrc}alt="logo" />

            </div>
            <Typography 
                sx={{color:sso==='Google'?'black':'white'}}
             variant="h6" 
             className="ssoText">
                Continue with {sso}
                </Typography>
        </div>
    )
}

export default CustomButton
