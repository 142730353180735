import { Panel, PanelGroup } from "react-resizable-panels";
import SubHeader from "./Components/SubHeader";
import WorkspaceSettings from "./Workspace/WorkspaceSettings";
import NotificationsSettings from "./Notifications/NotificationsSettings";
import ProfileSettings from "./Profile/ProfileSettings";
import SupportSettings from "./Support/SupportSettings";
import TeamSettings from "./Team/TeamSettings";
import { useState } from 'react';
import Layout from '../../components/Pagelayout/Layout';


const Settings = () => {
    const [activeTab, setActiveTab] = useState('workspace');

    const renderContent = () => {
        switch (activeTab) {
            case 'workspace':
                return <WorkspaceSettings />;
            case 'profile':
                return <ProfileSettings />;
            case 'team':
                return <TeamSettings />;
            case 'notification':
                return <NotificationsSettings />;
            case 'support':
                return <SupportSettings />;
            default:
                return <WorkspaceSettings />;
        }
    };

    return (
        <Layout>
        <div className="flex flex-col flex-1 min-h-0">
            <PanelGroup direction="vertical" className="flex-1 justify-start">
                <Panel
                    defaultSize={10}
                    className="bg-background"
                >
                    <SubHeader 
                        activeTab={activeTab}
                        onTabChange={setActiveTab}
                    />
                </Panel>
                <Panel 
                    defaultSize={90}
                    className="bg-background overflow-auto px-16 pb-16"
                >
                    {renderContent()}
                </Panel>
            </PanelGroup>
        </div>
        </Layout>
    );
};

export default Settings;