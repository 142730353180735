import React, { useEffect, useState, useRef } from "react";
import "./AddTC.css";
import { Snackbar, Alert, Popover } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    addTestCase,
    getAllAPIs,
    testIndividualTest,
} from "../../Actions/tcActions";
import APISequence from "./APISequence/APISequence";
import { fetchTC } from "../../Actions/tcActions";
import { useAuth } from "../../AuthContext";
import { PiCopy, PiPlus } from "react-icons/pi";
import ReqResDisplay from "./ResResDisplay/ReqResDisplay";
import HeaderRow from "./AddTCSidebar/HeaderRow/HeaderRow";
import { RiSubtractFill } from "react-icons/ri";
import { AiOutlineImport } from "react-icons/ai";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { getMethodClasses } from "../../utils/ui";
import Layout from '../Pagelayout/Layout';
import { FaRegSave } from "react-icons/fa";
import * as TemplateUtils from '../../utils/TestScriptTemplate';
import { IoMdPlay } from "react-icons/io";

/*

AddTC component that renders the Add Test Case page of the application.
It uses the useNavigate hook from react-router-dom to navigate to different routes.
It contains a form to add a new test case.
The form includes fields for Test Case Title, Priority, and API Sequence.
It also includes a button to add a new API sequence.

*/

const AddTC = () => {
    const [allApis, setAllApis] = useState([]);
    const [template, setTemplate] = useState(
        TemplateUtils.createNewTemplate()
    );
    const [currentApi, setCurrentApi] = useState(null);
    const [addedApis, setAddedApis] = useState([]);
    const [description, setDescription] = useState(null);
    const [reqBody, setReqBody] = useState(null);
    const [resContent, setResContent] = useState(null);
    const [headers, setHeaders] = useState({});
    const [extra, setExtra] = useState({});
    const [extraRes, setExtraRes] = useState({});
    const [popOpen, setPopOpen] = useState({ value: false, ind: 0, type: 'req', headerKey: "", headerKeys: {} });
    const [newKey, setNewKey] = useState(null)
    const [value, setValue] = useState(null)
    const [importAPI, setImportAPI] = useState('default');
    const [selected, setSelected] = useState({ value: '', ind: 0 })
    const [toggleState, setToggleState] = useState(1);
    const [flag, setFlag] = useState(false);
    const anchorEl = useRef(null);
    const resAnchorEl = useRef(null);
    const headerEl = useRef(null);
    const resRef = useRef(null);
    const reqRef = useRef(null);

    const navigate = useNavigate();

    const {
        allApis: apis,
        loading,
        message,
        error,
    } = useSelector((state) => state.apis); // Get the list of apis from the state

    const { user, apikey } = useAuth();

    const dispatch = useDispatch();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        // dispatch(fetchReqResByAPI(user?._id))
        dispatch(getAllAPIs());
    }, []);

    useEffect(() => {
        if (apis) {
            const tempApis = [];
            apis?.apiNames?.forEach((api) => {
                tempApis.push(api);
            });
            setAllApis(tempApis);
        }
    }, [apis]);

    const handleAddApi = (template, apis) => {
        let updatedTemplate = { ...template };
        apis.forEach((api) => {
            updatedTemplate = TemplateUtils.addApi(updatedTemplate, {
                ...api
            });
        })

        return updatedTemplate
    };

    const handleUpdateTemplate = (template, desc) => {
        const updatedTemplate = TemplateUtils.updateTemplate(template, {
            name: desc,
            description: desc
        });

        return updatedTemplate
    };

    const handleValidation = () => {
        const validationResult = TemplateUtils.validateTemplate(template);
        if (!validationResult.isValid) {
            console.error(validationResult.errors);
        }
    };

    const handleAddTC = async () => {
        try {
            if (flag) {
                dispatch(addTestCase({ template, apikey }));
            } else {
                setSnackbarOpen(true);
                setAlertSeverity("error");
                setAlertMessage("Please run the test case before adding it");
            }
        } catch (error) {
            // On error
            setAlertSeverity("error");
            setAlertMessage("Failed to Add Test Case");
            setSnackbarOpen(true);
        } finally {
            setAlertSeverity("success");
            setAlertMessage("Successfully Added Test Case");
            setSnackbarOpen(true);
        }
    };

    useEffect(() => {
        if (message) {
            dispatch(fetchTC());
            navigate("/test-suite");
        }
        if (error) {
            // console.log(error);
        }
    }, [message, error]);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setSnackbarOpen(true);
        setAlertSeverity("success");
        setAlertMessage("Copied to Clipboard");
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 1000);
    };

    const handleImport = () => {
        if (popOpen.type === 'header') {
            if (popOpen.headerKey) {
                let ind = addedApis.findIndex((api) => api.id === currentApi.id);
                let apiTemp = { ...addedApis[ind], headers: { ...headers, [popOpen.headerKey]: selected.value[1] } }
                setHeaders({ ...headers, [popOpen.headerKey]: selected.value[1] });
                addedApis[ind] = apiTemp;
                setAddedApis(addedApis);
            }
        } else if (popOpen.type === 'req') {
            let temp = { ...extra };
            temp[popOpen.ind] = typeof (selected.value[1]) === 'string' || typeof (selected.value[1]) === 'boolean' || typeof (selected.value[1]) === 'number' ? `"${selected.value[1]}"` : JSON.stringify(selected.value[1]);
            setExtra(temp);
            let ind = addedApis.findIndex((api) => api.id === currentApi.id);
            let path = addedApis[importAPI].variables.find((variable) => variable.name === selected.value[0]).path;
            let apiTemp = {
                ...addedApis[ind], replace_variables: [...addedApis[ind].replace_variables, {
                    name: selected.value[0],
                    path: path
                }]
            };
            addedApis[ind] = apiTemp;
            setAddedApis(addedApis);
        } else {
            let temp = { ...extraRes };
            temp[popOpen.ind] = typeof (selected.value[1]) === 'string' || typeof (selected.value[1]) === 'boolean' || typeof (selected.value[1]) === 'number' ? `"${selected.value[1]}"` : JSON.stringify(selected.value[1]);
            setExtraRes(temp);
            let ind = addedApis.findIndex((api) => api.id === currentApi.id);
            let path = addedApis[importAPI].variables.find((variable) => variable.name === selected.value[0]).path;
            let apiTemp = {
                ...addedApis[ind], replace_variables: [...addedApis[ind].replace_variables, {
                    name: selected.value[0],
                    path: path
                }]
            };
            addedApis[ind] = apiTemp;
            setAddedApis(addedApis);
        }
        if (popOpen.type === 'header') {
            setPopOpen({ ...popOpen, value: false, headerKeys: { ...popOpen.headerKeys, [currentApi.id]: { ...popOpen.headerKeys[currentApi.id], [popOpen.headerKey]: addedApis[importAPI] } } });
        } else {
            setPopOpen({ ...popOpen, value: false });
        }
    }

    const renderHeaders = () => {
        let headerArr = [];
        if (headers && Object.keys(headers).length > 0) {
            let formattedHeaders = Object.keys(headers).map(key => [key, headers[key]])
            formattedHeaders.map((header, index) => headerArr.push(<HeaderRow apiid={currentApi.id} setNewKey={setNewKey} setValue={setValue} popOpen={popOpen} setPopOpen={setPopOpen} key={index} ind={index} headerKey={header[0]} val={header[1]} headers={formattedHeaders} setHeaders={setHeaders} />))
        }
        return headerArr;
    }
    console.log(addedApis)

    const handleUpdateHeaderDependency = () => {
        let temp = { ...headers }
        console.log(temp)
        temp[newKey] = value
        if (newKey !== popOpen.headerKey) {
            delete temp[popOpen.headerKey]
        }
        let ind = addedApis.findIndex((api) => api.id === currentApi.id);
        let apiTemp = { ...addedApis[ind], headers: temp }
        addedApis[ind] = apiTemp;
        setAddedApis(addedApis);
        setHeaders(temp)
        setPopOpen({ ...popOpen, value: false })
    }

    const renderObject = (data, indent = 0) => {
        return Object.entries(typeof data === "string" ? JSON.parse(data) : data).map(([key, value], ind) => (
            <div className="">
                <div style={{ marginLeft: `${indent * 10}px` }} className="text-wrap break-words w-full cursor-pointer ">
                    {value !== null && ((Array.isArray(value) && value.length > 0) || (value.constructor === Object && Object.keys(value).length > 0)) ? (
                        // If value is an object, recursively render it
                        <div className="flex flex-col w-full ">
                            <span className="py-0.5 overflow-x-auto no-scrollbar px-2 py-1">{key}: {Array.isArray(value) ? '[' : '{'}</span>
                            <div className="flex flex-col">{renderObject(value, indent + 1)}</div>
                        </div>
                    ) : (
                        // Otherwise, display the key-value pair
                        <div className={"group flex justify-between items-center hover:bg-dashboard-dark-700 px-2 py-1 rounded w-full " + ((key + value) === selected.ind ? " border border-[#E27AAB]" : "")} onClick={() => setSelected({ value: [key, value], ind: (key + value) })}>
                            <span className="py-0.5 overflow-x-auto no-scrollbar">{key}: {(value && Array.isArray(value)) ? "[]" : (value && typeof value === "object") ? "{}" :String(value) ?? ''}</span>
                            <div className="hidden group-hover:block">{(key + value) === selected.ind ? <RiSubtractFill className="bg-[#1D1E20] p-1 flex items-center justify-center rounded-sm" size={22} /> : <AiOutlineImport className="bg-[#494949] cursor-pointer p-1 flex items-center justify-center rounded-sm" size={16} />}</div>
                        </div>
                    )}
                </div>
            </div>)
        );
    };

    const runTest = async () => {
        let temp = handleUpdateTemplate(template, description);
        let updatedTemplate = handleAddApi(temp, addedApis)
        setTemplate(updatedTemplate);
        // console.log('hello', updatedTemplate);
        try {
            dispatch(testIndividualTest(updatedTemplate, user?._id, apikey))
        } catch (error) {
            setAlertSeverity("error");
            setAlertMessage("Failed to Run Test Case");
            setSnackbarOpen(true);
            setFlag(false)
        } finally {
            setAlertSeverity("success");
            setAlertMessage("Successfully Ran Test Case");
            setSnackbarOpen(true);
            setFlag(true);
        }
    }

    return (
        <Layout loading={loading}>
            {/* <div className="w-full grid grid-cols-10 bg-[#080808] overflow-hidden text-white text-[#D9D9D9]">
            <div className="col-span-1">
                <Navbar />
            </div> */}
            <div className="col-span-9 w-full flex h-full">
                <div className="w-[20%] px-2 py-4 border-r border-[#2C2E33] h-full">
                    <div className="flex gap-6 text items-center mb-12">
                        <MdOutlineArrowBackIos className="#B0B0B0 cursor-pointer" onClick={() => navigate("/test-suite")} />
                        <span className="text-[#A0A0A0]">Add New Test Case</span>
                    </div>
                    <div className="text-white">Add Test Steps</div>
                    <APISequence
                        setHeaders={setHeaders}
                        setResContent={setResContent}
                        setReqBody={setReqBody}
                        allApis={allApis}
                        setCurrentApi={setCurrentApi}
                        addedApis={addedApis}
                        currentApi={currentApi}
                        setAddedApis={setAddedApis}
                    />
                </div>
                <div className="w-[80%] px-3 py-4 overflow-y-scroll no-scrollbar h-full">
                    <div className="tc-title">
                        <div className="flex justify-between items-center">
                            <div className="text-[#B0B0B0] text-lg">
                                Test Case Description <span className="text-[#D9509B]">*</span>
                            </div>
                            <div className="flex gap-2">
                                <div className={"flex gap-2 items-center rounded-md px-2 py-1 border border-[#2C2E33] mb-2 cursor-pointer " + (!description || addedApis.length === 0 ? "" : "bg-[#1D1E20]")} onClick={!description || addedApis.length === 0 ? () => { } : runTest}>
                                    <IoMdPlay /> Run Test Case
                                </div>
                                <div className={"flex gap-2 items-center rounded-md px-2 py-1 border border-[#2C2E33] mb-2 cursor-pointer " + (!description || addedApis.length === 0 || !flag ? "" : "bg-[#1D1E20]")} onClick={(!description || !addedApis[0]) ? () => {
                                    setSnackbarOpen(true);
                                    setAlertSeverity("error");
                                    setAlertMessage(!description ? "Please enter a description" : "Please add an API");
                                    setTimeout(() => {
                                        setSnackbarOpen(false);
                                    }, 1000);
                                } : handleAddTC}>
                                    <FaRegSave /> Save Test Case
                                </div>
                            </div>
                        </div>
                        <div className="rounded-md border border-[#2C2E33] bg-inherit w-full">
                            <textarea
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Enter Test Case Description"
                                className="w-full min-h-[20vh] bg-inherit p-2 focus:outline-none active:outline-none"
                            ></textarea>
                        </div>
                    </div>
                    <div className="w-full rounded-md border border-[#2C2E33] py-4 flex items-center my-4">
                        <div className="px-4 border-r border-[#2C2E33]"><span className={"rounded-full px-4 py-1 text-xs " + getMethodClasses(currentApi?.method)}>{currentApi?.method}</span></div>
                        <div className="px-4">{currentApi?.endpoint}</div>
                    </div>
                    <div className="rounded border border-[#2C2E33] mb-6">
                        <div className="border-b border-[#2C2E33]"><div className="border-b-2 border-[#E27AAB] p-2.5 max-w-fit">Headers</div></div>
                        <div className="max-h-[20vh] overflow-y-auto no-scrollbar">
                            <div className="grid grid-cols-12 border-b border-[#2C2E33]" ref={headerEl}>
                                <div className="col-span-1" />
                                <div className="col-span-4 px-3 py-2 border-x border-[#2C2E33]">Key</div>
                                <div className="col-span-7 px-3 py-2">Value</div>
                            </div>
                            {renderHeaders()}
                        </div>
                    </div>
                    <div>
                        <div className="flex gap-2 items-center mb-4">
                            <div onClick={() => setToggleState(1)} className={"px-4 py-1 cursor-pointer rounded-md " + (toggleState === 1 ? "bg-[#1D1E20] text-white" : "border border-[#2C2E33] text-[#A0A0A0]")}>Request</div>
                            <div onClick={() => setToggleState(2)} className={"px-4 py-1 cursor-pointer rounded-md " + (toggleState === 2 ? "bg-[#1D1E20] text-white" : "border border-[#2C2E33] text-[#A0A0A0]")}>Response</div>
                        </div>
                        <div className="rounded-md border border-[#2C2E33] p-4 w-full">
                            <div
                                ref={toggleState === 1 ? reqRef : resRef}
                                className="p-3 w-full h-[26vh] overflow-scroll no-scrollbar bg-[#08040A] focus:outline-none active:outline-none relative"
                                type="text"
                            >
                                <PiCopy size={16} className="text-[#494949] absolute top-4 right-4 cursor-pointer" onClick={() => copyToClipboard(toggleState === 1 ? reqBody : resContent)} />
                                {toggleState === 1 ? reqBody && reqBody.split("\n").map((line, index) => <ReqResDisplay
                                    index={index}
                                    line={line}
                                    reqBody={reqBody}
                                    setReqBody={setReqBody}
                                    anchorEl={anchorEl}
                                    extra={extra}
                                    setPopOpen={setPopOpen}
                                    popOpen={popOpen}
                                    key={index}
                                />
                                ) : resContent && resContent.split("\n").map((line, index) => <ReqResDisplay
                                    index={index}
                                    line={line}
                                    resContent={resContent}
                                    setResContent={setResContent}
                                    resAnchorEl={resAnchorEl}
                                    extraRes={extraRes}
                                    setPopOpen={setPopOpen}
                                    popOpen={popOpen}
                                    key={index}
                                />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popover
                open={popOpen.value}
                onClose={() => setPopOpen({ ...popOpen, value: false })}
                anchorEl={popOpen.type === 'req' ? anchorEl.current : popOpen.type === 'res' ? resAnchorEl.current : headerEl.current}
                className="no-scrollbar"
                slotProps={{
                    paper: {
                        style: {
                            width: popOpen.type === "header" ? "50vw" : "40vw",
                            backgroundColor: "#0F1011",
                            color: "#B0B0B0",
                            boxShadow: "none",
                            borderRadius: "12px",
                            border: "1px solid #3D3D3D",
                            padding: "1rem",
                            maxHeight: "50vh",
                            overflowY: "scroll",
                            display: "flex",
                            flexDirection: "column",
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                            "&::WebkitScrollbar": {
                                display: "none",
                            }
                        },
                    }
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: popOpen.type === 'res' ? "bottom" : "top",
                    horizontal: "left",
                }}
                anchorPosition={
                    {
                        top: 0,
                        left: -500
                    }
                }
            >
                {(popOpen.type === 'header' && (popOpen?.headerKeys[currentApi.id] && popOpen?.headerKey in popOpen?.headerKeys[currentApi.id])) ? <React.Fragment>
                    <div
                        onClick={() => setPopOpen({ ...popOpen, value: false })}
                        className="origin-center rotate-45 cursor-pointer text-[#B0B0B0] self-end"
                    >
                        <PiPlus size={20} />
                    </div>
                    <div className="text-[#E27AAB] font-semibold">Dependency Value</div>
                    <div className="grid grid-cols-5 gap-4 justify-between items-center">
                        <span>Key</span>
                        <input value={newKey} onChange={(e) => setNewKey(e.target.value)} className="col-span-4 bg-[#0F1011] rounded-md border border-[#2C2E33] w-full px-3 py-2 focus:outline-none active:outline-none" placeholder="currency" />
                    </div>
                    <div className="grid grid-cols-5 gap-4 justify-between items-center my-2">
                        <span>Value</span>
                        <input value={value} onChange={(e) => setValue(e.target.value)} className="col-span-4 bg-[#0F1011] rounded-md border border-[#2C2E33] w-full px-3 py-2 focus:outline-none active:outline-none" placeholder="currency" />
                    </div>
                    <div className="grid grid-cols-5 gap-4 justify-between items-center mb-4">
                        <span>API</span>
                        <div className="col-span-4 bg-[#0F1011] rounded-md border border-[#2C2E33] w-full px-3 py-2 flex gap-3 items-center">
                            <div className=""><span className={"rounded-full px-4 py-1 text-xs " + getMethodClasses(popOpen.headerKeys[currentApi.id][popOpen.headerKey]?.method)}>{popOpen.headerKeys[currentApi.id][popOpen.headerKey]?.method}</span></div>
                            <span>{popOpen.headerKeys[currentApi.id][popOpen.headerKey]?.name}</span>
                        </div>
                    </div>
                    <div className={"px-3 py-1 text-white rounded-md max-w-fit self-end justify-self-end " + (importAPI === 'default' ? "bg-[#222222]" : "bg-[#D9509B] hover:bg-[#C0408C] cursor-pointer")} onClick={importAPI === 'default' ? () => console.log("") : handleUpdateHeaderDependency}>Update Dependency</div>
                </React.Fragment> : <React.Fragment>
                    <div
                        onClick={() => setPopOpen({ ...popOpen, value: false })}
                        className="origin-center rotate-45 cursor-pointer text-[#B0B0B0] self-end"
                    >
                        <PiPlus size={20} />
                    </div>
                    <div className="font-semibold my-2">Select API</div>
                    <select className="bg-[#0F1011] px-3 py-2 py-0.5 rounded-md border border-[#2C2E33]" value={importAPI} onChange={(e) => setImportAPI(e.target.value)}><option disabled="disabled" value={'default'}>Select an API</option>{addedApis.map((api, index) => index < addedApis.indexOf(currentApi) ? <option value={index}>{api.name}</option> : null)}</select>
                    {importAPI !== 'default' ? <div className="font-semibold my-2">{popOpen.type === 'header' ? "Headers" : "Response Content"}</div> : null}
                    {importAPI !== 'default' ? <div className="rounded-md bg-[#080808] border border-[#2C2E33]">
                        {popOpen.type === "header" ? <div className="grid grid-cols-3">
                            <div className="px-3 py-1.5 col-span-1 border-b border-r border-[#2C2E33]">Key</div>
                            <div className="px-3 py-1.5 col-span-2 border-b border-[#2C2E33]">Value</div>
                        </div> : null}
                        {popOpen.type === 'header' ? addedApis[importAPI].headers && Object.entries(addedApis[importAPI].headers).map((el, ind) => <div>
                            <div className={"bg-[#080808] grid grid-cols-3 group" + (ind === selected.ind ? " bg-[#1D1E20]" : "")} onClick={() => setSelected({ value: el, ind })}>
                                <div className="px-3 col-span-1 py-1.5 border-b border-r border-[#2C2E33] overflow-x-auto no-scrollbar">{el[0]}</div>
                                <div className="col-span-2 border-b border-[#2C2E33] flex justify-between gap-2 items-center">
                                    <div className="px-3 py-1.5 text-wrap break-words overflow-x-auto no-scrollbar">{typeof (el[1]) === 'string' || typeof (el[1]) === 'boolean' || typeof (el[1]) === 'number' ? el[1] : JSON.stringify(el[1])}</div>
                                    <div className="hidden group-hover:block mr-2">{ind === selected.ind ? <RiSubtractFill className="bg-[#494949] p-0.5 flex items-center justify-center rounded-sm" size={22} /> : <AiOutlineImport className="bg-[#494949] p-1 cursor-pointer flex items-center justify-center rounded-sm" size={20} />}</div>
                                </div>
                            </div>
                        </div>) : <div className="p-4 overflow-auto no-scrollbar">{addedApis[importAPI].response.content ? renderObject(addedApis[importAPI].response.content) : null}</div>}</div> : null}
                    <div className={"px-3 py-1 text-white rounded-md max-w-fit self-end justify-self-end mt-4 " + (importAPI === 'default' ? "bg-[#222222]" : "bg-[#D9509B] hover:bg-[#C0408C] cursor-pointer")} onClick={importAPI === 'default' ? () => console : handleImport}>Import</div>
                </React.Fragment>}
            </Popover>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
            {/* </div> */}
        </Layout>
    );
};

export default AddTC;
