import SettingsHeading from '../Components/SettingsHeading';
import { Panel } from 'react-resizable-panels';

const NotificationsSettings = () => {
    return (
        <div>
            <Panel>
                <SettingsHeading variant='notifications'>
                    <input
                        type="text"
                        placeholder="example@gmail.com"
                        className="w-[332px] px-[14px] mt-4 py-[10px] text-text-primary rounded-[6px] bg-[#0F1011] border border-[#2C2E33] transition-colors duration-200 focus:outline-none focus:border-primary"
                    />
                </SettingsHeading>
            </Panel>
            <Panel>
                <div className="flex flex-col gap-[12px]">
                    <div className="text-text-primary font-bold text-h5">
                        Get Updates On
                    </div>
                    <div className="w-[276px] h-[41px] flex px-[14px] py-[10px] gap-[50px] border rounded-[6px] border-[#2C2E33]">
                        <label className="flex items-center ">
                            <input
                                type="checkbox"
                                className="w-4 h-4 border border-primary accent-primary bg-transparent"
                            />
                            <span className="ml-2 text-text-primary text-h5">Failed Test Cases</span>
                        </label>
                    </div>

                    <div className="w-[276px] h-[41px] flex px-[14px] py-[10px] gap-[50px] border rounded-[6px] border-[#2C2E33]">
                        <label className="flex items-center ">
                            <input
                                type="checkbox"
                                className="w-4 h-4 border border-primary accent-primary bg-transparent "
                            />
                            <span className="ml-2 text-text-primary text-h5">Test Execution Completion</span>
                        </label>
                    </div>
                </div>
            </Panel>
        </div>
    );
};

export default NotificationsSettings;