import React from 'react';

const Button = ({
    children,
    variant = 'primary',
    size = 'medium',
    disabled = false,
    fullWidth = false,
    type = 'button',
    onClick,
    className = '',
    isActive = true,
}) => {
    const baseStyles = 'rounded-[5px] font-medium transition-all duration-200 flex items-center justify-center focus:outline-none';
    
    const variants = {
        primary: `
            ${!isActive ? 'bg-gradient-primary hover:bg-primary-hover' : 'bg-primary-active'}  
            text-text-primary disabled:bg-primary-disabled
            outline outline-primary-outline
        `,
        secondary: `
            ${!isActive ? 'bg-secondary hover:bg-secondary-hover' : 'bg-primary-active'} 
            ${!isActive ? 'text-text-inverse disabled:bg-secondary-disabled' : 'text-text-primary disabled:bg-primary-active'}
            outline outline-secondary-outline
        `,
        tertiary: `
            bg-tertiary hover:bg-tertiary-hover active:bg-tertiary-active 
            text-text-inverse disabled:bg-tertiary-disabled
            outline outline-1 outline-tertiary
        `,
        outline: `
            border-2 border-primary hover:bg-primary/10 
            text-primary disabled:border-primary-disabled 
            disabled:text-text-disabled
            outline outline-1 outline-primary
        `,
        ghost: `
            bg-transparent hover:bg-surface-hover 
            text-primary disabled:text-text-disabled
            outline outline-1 outline-primary
        `
    };

    const sizes = {
        small: 'px-3 py-1.5 text-sm',
        medium: 'w-[91px] h-[26px] px-3 py-1 text-paragraph gap-2 rounded-tl-[5px] rounded-tr-0 rounded-br-0 rounded-bl-0 border-t-0 border-l-0 border-r-0 border-b-0',
        large: 'px-6 py-3 text-lg',
    };

    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={`
                ${baseStyles}
                ${variants[variant]}
                ${sizes[size]}
                ${fullWidth ? 'w-full' : ''}
                ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
                ${className}
            `}
        >
            {children}
        </button>
    );
};

export default Button;