import React, { useEffect, useState } from "react";
import "./BugTracker.css";
import CoverageComponent from "../CoverageComponent/CoverageComponent";
import IssuesTable from "../IssuesTable/IssuesTable";
import { Dialog, Typography } from "@mui/material";
import EditIcon from "../../icons/edit-icon.svg";
import DeleteIcon from "../../icons/delete-icon.svg";
import PlusIcon from "../../icons/plus-icon.svg";
import Navbar from "../Navbar/Navbar";
import Layout from '../Pagelayout/Layout';
import { Controls } from '../TestSuite/Controls';
import TestCard from "./TestCard";
import { Panel, PanelGroup } from 'react-resizable-panels';
import CoverageSidebar from "../TestSuite/CoverageSideBar";
import { useSelector } from 'react-redux';

/*

BugTracker component that renders the bug tracker page of the application.
It uses the useState hook to manage the state of the modal.
It contains a table that displays the list of bugs.
It uses the IssuesTable component to display the bugs.
It uses the Dialog component from Material-UI to display the modal.
It contains buttons to view base URLs and add new test cases.

*/
const BugTracker = () => {
  const testRunId = ["Test_Run_01", "Test_Run_02", "Test_Run_03"];

  const tests = useSelector((state) => state.test.tests);
  const [search, setSearch] = useState(null); // Use the search state to store the search query
  const [data, setData] = useState(null)

  const handleSearch = (value) => {
    setSearch(value);
    const searchedData = data?.original.filter((item) =>
      (item.name.toLowerCase().includes(value.toLowerCase()) || item.description.toLowerCase().includes(value.toLowerCase()))
    );
    setData({ ...data, current: value === '' ? data?.original : searchedData })
  }

  useEffect(() => {
    if (tests) {
      setData({ original: tests.filter((test) => test.pass === false), current: tests.filter((test) => test.pass === false) });
    }
  }, [tests]);

  const controlComponentsConfig = [
    { type: "TestRunSelector", props: { testRunId: testRunId }, group: "first" },
    { type: "SearchBar", props: { search: search, handleSearch: handleSearch }, group: "first" },
    { type: "FilterButton", group: "second" },
    { type: "SortButton", group: "second" },
    { type: "ShareButton", group: "second" }
  ];

  return (
    <Layout>
      <Controls components={controlComponentsConfig} />
      <div className="flex flex-col h-full">
        <PanelGroup direction="horizontal" className="flex-1 overflow-auto">
          <Panel minSize={80} className="overflow-auto pb-8 no-scrollbar">
            <div className="flex-1 overflow-auto px-2 py-4 h-full no-scrollbar">
              {data?.current.map((test, index) => (
                  <TestCard
                    index={index}
                    test={test}
                  />
                ))}
            </div>
          </Panel>
          <Panel minSize={20} className="overflow-auto no-scrollbar">
            <CoverageSidebar coverageTypes={['bug', 'priority']} />
          </Panel>
        </PanelGroup>
      </div>
    </Layout>
  );
};

export default BugTracker;
