import React from 'react'
import './HTTPCoverage.css'
import PieChart from '../../icons/pie-chart.png'
import { Typography } from '@mui/material'
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import TestStatus from '../EndpointCoverage/TestStatus/TestStatus';
import { LuRefreshCcw } from "react-icons/lu";


Chart.register(ArcElement, Tooltip, Legend, Title); // Create a chart with the necessary elements

/*

The HTTPCoverage component renders the HTTP coverage statistics for the endpoint.
It displays the number of HTTP requests made in a doughnut chart.
It uses the Doughnut component from react-chartjs-2 to render the chart.
The component also displays the number of HTTP requests made.
The component takes in the http prop to render the HTTP coverage statistics.

*/
const HTTPCoverage = ({ http }) => {

    const isNull = http === null;
    const get = http?.getCount
    const post = http?.postCount
    const put = http?.putCount
    const patch = http?.patchCount
    const del = http?.deleteCount
    const total = get + post + put + patch + del;

    // Data for the chart
    const data = {
        labels: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
        datasets: [{
            data: [get, post, put, patch, del],
            backgroundColor: [
                '#65DC8D',
                '#FCD34D',
                '#44A5FF',
                '#9D7CFF',
                '#F87171',
            ],
            borderColor: [
                '#65DC8D',
                '#FCD34D',
                '#44A5FF',
                '#9D7CFF',
                '#F87171',
            ],
            hoverOffset: 4,
        }],
        radius: 60,
    };

    // Chart design options
    const chartOptions = data !== null ? {
        cutout: '90%',
        plugins: {
            tooltip: {
                displayColors: false,
                backgroundColor: (context) => {
                    const label = context.tooltip.dataPoints[0].label || '';
                    switch (label) {
                        case 'GET':
                            return '#31744A';
                        case 'POST':
                            return '#816F33';
                        case 'PUT':
                            return '#2F5A83';
                        case 'PATCH':
                            return '#644583';
                        case 'DELETE':
                            return '#804343';
                        default:
                            return '#2C2E33';
                    }
                },
                enabled: true,
                callbacks: {
                    label: function (context) {
                        const value = context.formattedValue;
                        const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
                        const percentage = Math.round((value / total) * 100);
                        return `${percentage}%`;
                    }
                }
            },
            legend: {
                display: false, // Hides the labels on top of the chart
            },
        },
        maintainAspectRatio: false,
        elements: {
            arc: {
                hoverRadius: 0, // Adjust this value to control the size of the label square when hovering
                borderWidth: 0.01, // Removes the border of the label square
            }
        }
    } : null;

    return (
        <div className="bg-[#0F1011] rounded-md border border-[#2C2E33] p-4 w-full">
            <div className='flex justify-between items-center'>
                <span className='text-[#E27AAB]'>
                    HTTP Coverage
                </span>
                <LuRefreshCcw className='text-[#B0B0B0]' />
            </div>
            {http !== undefined ? <div className='relative my-12'>
                <Doughnut
                    data={data}
                    options={chartOptions}
                    height="150px"
                    width="150px"
                />
                <div className="absolute top-[40%] font-light text-[#F37BB3] left-1/2 -translate-x-1/2 mb-3 text-center">Total methods <div className='text-white font-regular text-lg'>{get + post + put + patch + del}</div></div>
            </div> :
                <>
                    <img src={PieChart} alt='Pie Chart' className='httpCoverage-image' />
                    <Typography variant='h6'>
                        Whoops! No data<br></br> available at the moment.
                    </Typography>
                </>
            }
            {http !== null ? <div className=''>
                <TestStatus
                    status={"get"}
                    val={Math.round((get*100)/total)}
                />
                <TestStatus
                    status={"post"}
                    val={Math.round((post*100)/total)}
                />
                <TestStatus
                    status={"put"}
                    val={Math.round((put*100)/total)}
                />
                <TestStatus
                    status={"patch"}
                    val={Math.round((patch*100)/total)}
                />
                <TestStatus
                    status={"delete"}
                    val={Math.round((del*100)/total)}
                />
            </div> : null}
        </div>
    )
}

export default HTTPCoverage
