// import axios from "axios";
import axiosInstance from "../axiosInterceptor";
import { config } from "../config";

let dev = config.dev
let dburl1 = dev === false ? "https://server-v3-7qxc7hlaka-uc.a.run.app" : "http://localhost:3000"
let test_executor_url = dev === false ? "https://test-executor-7qxc7hlaka-uc.a.run.app" : "http://localhost:8080"
let seq_agent_url = dev === false ? "https://sequencing-agent-yml-7qxc7hlaka-uc.a.run.app" : "http://localhost:8086"
let json_url = "https://json-schema-gen-7qxc7hlaka-uc.a.run.app"

// axios.defaults.withCredentials = true


export const fetchTC = () => async (dispatch) => {
    try {
        dispatch({
            type: "fetchTCRequest"
        });

        const response = await axiosInstance.get(`${dburl1}/api/get-tc`, {
            withCredentials: true
        });

        dispatch({
            type: "fetchTCSuccess",
            payload: response.data
        });
    } catch (error) {
        // console.log(error);

        dispatch({
            type: "fetchTCFailure",
            payload: error.message,
        });
    }
};

export const fetchTestScripts = () => async (dispatch) => {
    try {
        dispatch({
            type: "fetchTestScriptsRequest"
        });

        const response = await axiosInstance.get(`${dburl1}/api/last-testscript`, {
            withCredentials: true
        });

        dispatch({
            type: "fetchTestScriptsSuccess",
            payload: response.data
        });
    } catch (error) {
        // console.log(error);

        dispatch({
            type: "fetchTestScriptsFailure",
            payload: error.message,
        });
    }
};

export const deleteTestScript = (details) => async (dispatch) => {
    try {
        dispatch({
            type: "fetchTestScriptsRequest"
        });

        const response = await axiosInstance.patch(`${dburl1}/api/testscript`, {
            name: details.name,
            x_access_token: details.apikey
        }, {
            withCredentials: true
        });

        dispatch({
            type: "fetchTestScriptsSuccess",
            payload: response.data
        });
    } catch (error) {
        // console.log(error);

        dispatch({
            type: "fetchTestScriptsFailure",
            payload: error.message,
        });
    }
};

export const runAllTests = (token) => async (dispatch) => {

    try {
        dispatch({
            type: "runAllTestsRequest"
        })
        const response = await axiosInstance.post(`${test_executor_url}/execute`, {}, {
            headers: {
                'x-access-token': token
            }
        });
        dispatch({
            type: "runAllTestsSuccess",
            payload: response.data
        })
    } catch (error) {
        // console.log(error)
        dispatch({
            type: "runAllTestsFailure",
            payload: error.message
        })
    }
}

export const fetchAllTC = (id) => async (dispatch) => {
    try {
        dispatch({
            type: "fetchAllTCRequest"
        });

        const response = await axiosInstance.get(`${dburl1}/api/get-all-apis?suiteId=${id}`, {
            withCredentials: true
        });
        dispatch({
            type: "fetchAllTCSuccess",
            payload: response.data
        });

    } catch (error) {
        // // console.log(error);
        // // console.log(error.message);
        dispatch({
            type: "fetchAllTCFailure",
            payload: error.message,
        });
    }
}

export const fetchIndividualAPI = (id) => async (dispatch) => {
    try {
        dispatch({
            type: "fetchIndividualAPIRequest"
        });
        const response = await axiosInstance.get(`${dburl1}/api/get-api/${id}`, {
            withCredentials: true
        });
        dispatch({
            type: "fetchIndividualAPISuccess",
            payload: response.data
        })

    } catch (error) {
        dispatch({
            type: "fetchIndividualAPIFailure",
            payload: error.message
        })
    }
}

export const fetchIndividualAssertions = (id) => async (dispatch) => {
    try {
        dispatch({
            type: "fetchIndividualAssertionsRequest"
        });
        const response = await axiosInstance.get(`${dburl1}/api/get-api-assertions/${id}`, {
            withCredentials: true
        });
        dispatch({
            type: "fetchIndividualAssertionsSuccess",
            payload: response.data
        })

    } catch (error) {
        dispatch({
            type: "fetchIndividualAssertionsFailure",
            payload: error.message
        })
    }
}

export const fetchReqResByAPI = (userid, token) => async (dispatch) => {
    try {
        dispatch({
            type: "fetchReqResByAPIRequest"
        });

        let config = {
            method: 'get',
            url: `${dburl1}/api/get-req-res-api?userid=${userid}`,
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        };

        const response = await axiosInstance(config)

        dispatch({
            type: "fetchReqResByAPISuccess",
            payload: response.data
        })

    } catch (error) {
        dispatch({
            type: "fetchReqResByAPIFailure",
            payload: error.message
        })
    }
}

export const getAllAPIs = () => async (dispatch) => {
    try {
        dispatch({
            type: "getAllAPIsRequest"
        });
        const response = await axiosInstance.get(`${dburl1}/api/get-sources`, {
            withCredentials: true
        })
        dispatch({
            type: "getAllAPIsSuccess",
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: "getAllAPIsFailure",
            payload: error.message
        })
    }
}

export const getAPIKey = (apiKeyId) => async (dispatch) => {
    try {
        dispatch({
            type: "getAPIKeyRequest"
        });
        const response = await axiosInstance.get(`${dburl1}/api/get-key/${apiKeyId}`)
        dispatch({
            type: "getAPIKeySuccess",
            payload: response.data.apiKey
        })
    } catch (error) {
        dispatch({
            type: "getAPIKeyFailure",
            payload: "Failed to get API key"
        })
    }
}

export const getAPICount = (apiKeyId) => async (dispatch) => {
    try {
        dispatch({
            type: "getAPICountRequest"
        });
        const response = await axiosInstance.get(`${dburl1}/api/getApiCount`, {
            withCredentials: true
        })
        dispatch({
            type: "getAPICountSuccess",
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: "getAPICountFailure",
            payload: error.message
        })
    }
}

export const addNamedApi = (apiDetails) => async (dispatch) => {
    try {
        dispatch({
            type: "addNamedApiRequest"
        });

        const response = await axiosInstance.post(`${dburl1}/api/addNamedApi`, {
            name: apiDetails.apiName,
            request: {
                method: apiDetails.apiMethod.toUpperCase(),
                path: apiDetails.apiEndpoint,
                body: apiDetails.body,
                headers: apiDetails.headers,
            },
            response: {
                content: apiDetails.responseBody.data,
                status_code: apiDetails.responseBody.statusCode,
            },
        }, {
            withCredentials: true
        })
        // console.log(response.data)

        dispatch({
            type: "addNamedApiSuccess",
            payload: "API added successfully"
        })
        return response;
    } catch (error) {
        dispatch({
            type: "addNamedApiFailure",
            payload: error.message
        })
        throw error
    }
}

export const getResponseBody = (apiDetails) => async (dispatch) => {
    try {
        dispatch({
            type: "getResponseBodyRequest"
        });

        const resp = await axiosInstance.post(`${dburl1}/api/getResponseBody`, {
            body: apiDetails.body ?? null,
            headers: apiDetails.headers,
            path: apiDetails.serverName + apiDetails.apiEndpoint,
            method: apiDetails.apiMethod.toUpperCase(),
        }, {
            withCredentials: true
        })

        dispatch({
            type: "getResponseBodySuccess",
            payload: resp.data
        })

    } catch (error) {
        dispatch({
            type: "getResponseBodyFailure",
            payload: error.message
        })
    }
}

export const uploadPRD = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: "addNamedApiRequest"
        });
        const response = await axiosInstance.post(`${dburl1}/api/uploadprd`, formData, {
            withCredentials: true
        })

        dispatch({
            type: "addNamedApiSuccess",
            payload: "File uploaded successfully"
        })
    } catch (error) {
        dispatch({
            type: "addNamedApiFailure",
            payload: error.message
        })
    }
}

export const getUserApis = (userid) => async (dispatch) => {
    //user apis with names
    try {
        dispatch({
            type: "requestNamesRequest"
        });
        const response = await axiosInstance.post(`${seq_agent_url}/generate_names`, { userid }, {
            withCredentials: true
        })

        dispatch({
            type: "requestNamesSuccess",
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: "requestNamesFailure",
            payload: error.message
        })
    }
}

export const deleteNamedUserApis = (body) => async (dispatch) => {
    try {
        dispatch({
            type: "addNamedApiRequest"
        });
        const response = await axiosInstance.post(`${dburl1}/api/shift-user-apis`, body, {
            withCredentials: true
        })

        dispatch({
            type: "addNamedApiSuccess",
            payload: "APIs deleted successfully"
        })
    } catch (error) {
        dispatch({
            type: "addNamedApiFailure",
            payload: error.message
        })
    }
}

export const getSwaggerDoc = (body) => async (dispatch) => {
    try {
        dispatch({
            type: "getSwaggerDocRequest"
        });
        const response = await axiosInstance.get(`${dburl1}/api/get-swagger-doc`, {
            withCredentials: true
        })

        dispatch({
            type: "getSwaggerDocSuccess",
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: "getSwaggerDocFailure",
            payload: error.message
        })
    }
}

export const getResponseSchema = (body) => async (dispatch) => {
    try {
        dispatch({
            type: "addNamedApiRequest"
        });
        const response = await axiosInstance.post(`${dburl1}/api/getResponseSchema`, body, {
            withCredentials: true
        })
        // console.log(response.data)

        dispatch({
            type: "addNamedApiSuccess",
        })
        return response.data.data;
    } catch (error) {
        dispatch({
            type: "addNamedApiFailure",
            payload: error.message
        })
    }
}

const responseSchema = (apis) => async (dispatch) => {
    try {
        apis.forEach(async api => {
            const resp = await axiosInstance.post(`${json_url}/infer-schema`, {
                "jsons": [api.response.content]
            }, {
                withCredentials: false
            })
            api["responseSchema"] = resp.data
        })
    } catch (error) {
        // console.log(error)
        dispatch({
            type: "addNamedApiFailure",
            payload: error.message
        })
    }
}

export const addTestCase = (body) => async (dispatch) => {
    try {
        dispatch({
            type: "addNamedApiRequest"
        });
        // console.log(body.apis)
        const response = await axiosInstance.post(`${dburl1}/api/update-testscript`, {
            "script": body.template,
            "x_access_token": body.apikey,
        }, {
            withCredentials: true
        }).then(async response => {
            const testResponse = await axiosInstance.post(`${test_executor_url}/execute`, {}, {
                headers: {
                    'x-access-token': body.apikey
                }
            })
            console.log(testResponse.data)
        })
        dispatch({
            type: "addNamedApiSuccess",
        })

    } catch (error) {
        dispatch({
            type: "addNamedApiFailure",
            payload: error.message
        })
    }
}

export const addTestCaseAPI = (body) => async (dispatch) => {
    try {
        dispatch({
            type: "addNamedApiRequest"
        });
        // console.log(body.api)

        const resp = await axiosInstance.post(`${json_url}/infer-schema`, {
            "jsons": [body.api.response]
        }, {
            withCredentials: false
        })
        body.api["responseSchema"] = resp.data

        const response = await axiosInstance.patch(`${dburl1}/api/testscript/api`, {
            tcName: body.tcName,
            api: body.api,
            x_access_token: body.apikey
        }, {
            withCredentials: true
        })
        dispatch({
            type: "addNamedApiSuccess",
        })

    } catch (error) {
        dispatch({
            type: "addNamedApiFailure",
            payload: error.message
        })
    }
}

export const triggerE2E = (baseUrl, token) => async (dispatch) => {
    try {
        dispatch({
            type: "triggerE2ERequest"
        });
        let data = {
            url: baseUrl,
            x_access_token: token
        }

        const baseUrlResp = await axiosInstance.post(`${dburl1}/api/add-base-url`, {
            url: baseUrl,
        }, {
            withCredentials: true
        })

        const response = await axiosInstance.post(`${dburl1}/api/trigger-e2e-flow`, data, {
            withCredentials: true
        })
        // console.log(response)
        dispatch({
            type: "triggerE2ESuccess"
        })
    } catch (error) {
        dispatch({
            type: "triggerE2EFailure",
            payload: error.message
        })
    }
}

export const getSources = () => async (dispatch) => {
    try {
        dispatch({
            type: "getSourcesRequest"
        });
        const response = await axiosInstance.get(`${dburl1}/api/get-unique-sources`, {
            withCredentials: true
        })
        dispatch({
            type: "getSourcesSuccess",
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: "getSourcesFailure",
            payload: error.message
        })
    }
}

export const getBaseURLs = () => async (dispatch) => {
    try {
        dispatch({
            type: "getBaseURLsRequest"
        });
        const response = await axiosInstance.get(`${dburl1}/api/get-base-url`, {
            withCredentials: true
        })
        dispatch({
            type: "getBaseURLsSuccess",
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: "getBaseURLsFailure",
            payload: error.message
        })
    }
}

export const addBaseURL = (urls) => async (dispatch) => {
    try {
        dispatch({
            type: "addBaseURLsRequest"
        });
        const response = await axiosInstance.post(`${dburl1}/api/add-base-url`, { urls }, {
            withCredentials: true
        })
        dispatch({
            type: "addBaseURLsSuccess",
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: "addBaseURLsFailure",
            payload: error.message
        })
    }
}

export const executeIndividualTest = (testCase, userId, key) => async (dispatch) => {
    try {
        dispatch({
            type: "addNamedApiRequest"
        });
        const response = await axiosInstance.post(`${test_executor_url}/execute-individual`, {
            code: [testCase],
            userId
        }, {
            headers: { 'x-access-token': key },
            withCredentials: true
        })
        dispatch({
            type: "addNamedApiSuccess",
            payload: "Execution Started"
        })
    }
    catch (error) {
        dispatch({
            type: "addNamedApiFailure",
            payload: error.message
        })
    }
}

export const testIndividualTest = (body, userId, key) => async (dispatch) => {
    try {
        // dispatch({
        //     type: "addNamedApiRequest"
        // });
        console.log(body.apis)
        body.apis.forEach(async api => {
            await axiosInstance.post(`${json_url}/infer-schema`, {
                "jsons": [api.response.content]
            }, {
                withCredentials: false
            }).then(async response => {
                api["responseSchema"] = response.data
            })
            if (api == body.apis[body.apis.length - 1]) {
                const response = await axiosInstance.post(`${test_executor_url}/execute-individual?test=true`, {
                    code: [body],
                    userId
                }, {
                    headers: { 'x-access-token': key },
                    withCredentials: true
                })
                // dispatch({
                //     type: "addNamedApiSuccess",
                //     payload: "Execution Started"
                // })
            }
        })
    }
    catch (error) {
        dispatch({
            type: "addNamedApiFailure",
            payload: error.message
        })
    }
}

export const getTestSteps = (userid) => async (dispatch) => {
    try {
        dispatch({
            type: "getTestStepsRequest"
        });
        // console.log("userid from dispatcgh", userid)
        const response = await axiosInstance.get(`${dburl1}/api/get-testSteps/${userid}`,
            {
                withCredentials: true
            })
        // console.log(response.data)
        dispatch({
            type: "getTestStepsSuccess",
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: "getTestStepsFailure",
            payload: error.message
        })
    }
}


export const generateChainandExecute = (step, baseURL, key) => async (dispatch) => {
    try {
        dispatch({
            type: "generateChainandExecuteRequest"
        });
        const response = await axiosInstance.post(`${dburl1}/api/trigger-chaining-execute`, {
            "x_access_token": key,
            test_steps: step,
            url: baseURL
        }, {
            withCredentials: true
        })
        dispatch({
            type: "generateChainandExecuteSuccess",
            payload: "Execution Started"
        })
    }
    catch (error) {
        dispatch({
            type: "generateChainandExecuteFailure",
            payload: error.message
        })
    }
}

export const updateAPI = (details, id) => async (dispatch) => {
    try {
        dispatch({
            type: "fetchIndividualAPIRequest"
        });
        const response = await axiosInstance.patch(`${dburl1}/api/get-api/${id}`, details, {
            withCredentials: true
        })
        dispatch({
            type: "fetchIndividualAPISuccess",
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: "fetchIndividualAPIFailure",
            payload: error.message
        })
    }
}

export const downloadSwaggerDoc = () => async (dispatch) => {
    try {
        dispatch({
            type: "getSwaggerDocRequest"
        });
        const response = await axiosInstance.post(`${dburl1}/api/generate-swagger-doc`, {
            withCredentials: true
        })
        dispatch({
            type: "getSwaggerDocSuccess",
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: "getSwaggerDocFailure",
            payload: error.message
        })
    }
}