import React from 'react'
import './TestStatus.css'
import { Typography } from '@mui/material'

/*

The TestStatus component renders the status of the test case.
It displays the status of the test case and the value of the status.
The component uses the status prop to determine the color of the text.


*/
const TestStatus = ({ status, val, isBugs }) => {
  const getColor = (status) => {
    let textColor = '';
    // Set the text color based on the status prop
    switch (status) {
      case 'Passed':
        textColor = 'bg-[#4ADE80]';
        break;
      case 'Failed':
        textColor = 'bg-[#F87171]';
        break;
      case 'get':
        textColor = "bg-[#65DC8D]"
        break;
      case 'post':
        textColor = "bg-[#FCD34D]"
        break;
      case 'put':
        textColor = "bg-[#44A5FF]"
        break;
      case 'patch':
        textColor = "bg-[#9D7CFF]"
        break;
      case 'delete':
        textColor = "bg-[#F87171]"
        break;
    }
    return textColor
  }

  let name = isBugs ? 'bugs' : 'test';
  return (
    <div className='flex justify-between items-center text-[#D9D9D9]'>
      <div className='flex gap-2 items-center'>
        <div className={"w-[10px] h-[10px] rounded-full " + getColor(status)} />
        <spa className="capitalize">{status}</spa>
      </div>
      <p>
        {val}%
      </p>
    </div>
  )
}

export default TestStatus
