import EditIcon from "../../../icons/edit-icon.svg";
import { useState } from "react";
import { RiEditBoxLine, RiSaveLine } from "react-icons/ri";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

const BaseUrl = ({ source, sources, ind }) => {
  const [edit, setEdit] = useState(source[0]==="" ? true : false);
  const [newSource, setNewSource] = useState(source[0] ?? "");
  const [url, setUrl] = useState(source[1] ?? "");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const isValidBaseUrl = (url) => {
    try {
      const parsedUrl = new URL(url);

      // Ensure that the URL includes a protocol and hostname
      return (
        ["http:", "https:"].includes(parsedUrl.protocol) && !!parsedUrl.hostname
      );
    } catch (e) {
      // console.log("error", e);
      return false; // Invalid URL
    }
  };

  const handleSave = (e) => {
    e.preventDefault()
    if (isValidBaseUrl(url)) {
      setEdit(false)
      sources[ind][1] = url
      setAlertSeverity("success")
      setAlertMessage("Base URL saved successfully")
      setSnackbarOpen(true)
    }
    else {
      setAlertSeverity("error")
      setAlertMessage("Invalid Base URL")
      setSnackbarOpen(true)
      return
    }
  }

  const handleURLSave = (e) => {
    setUrl(e.target.value)
  }

  const handleSourceSave = (e) => {
    setNewSource(e.target.value)
    sources[ind][0] = e.target.value
  }

  return <div className={ind === sources.length - 1 ? "" : "border-b border-[#2C2E33]"}>
    <form onSubmit={(e) =>handleSave(e)} className="grid grid-cols-3">
      <input placeholder="Source name" onChange={handleSourceSave} disabled={!edit} value={newSource} className="col-span-1 bg-inherit rounded-l-md px-3 py-2 border-r border-[#2C2E33]" />
      <div className="px-3 py-2 col-span-2 flex items-center justify-between gap-2">
        <input type="url" pattern="https?://.*" placeholder="https://api-example.com" onChange={handleURLSave} disabled={!edit} value={url} className="bg-inherit w-full border-none overflow-x-scroll" />
        {edit ? (
          <Button
            type="submit"
            variant="outlined"
            sx={{
              backgroundColor: '#17181A',
              border: '1px solid #2C2E33',
              borderRadius: '4px',
              padding: '8px 12px',
              minWidth: 'auto',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#E45799',
              },
            }}
          >
            <RiSaveLine size={16} />
          </Button>
        ) : (
          <div
            onClick={() => setEdit(true)}
            className="bg-[#17181A] border border-[#2C2E33] rounded px-3 py-2 cursor-pointer hover:bg-[#E45799] transition-colors duration-200"
          >
            <RiEditBoxLine size={16} color="#fff" />
          </div>
        )}
      </div>
    </form>
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={10000}
      onClose={handleSnackbarClose}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={alertSeverity}
        sx={{ width: "100%" }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  </div>
}

export default BaseUrl;
