const AddAPIHeaders = ({headers, setHeaders}) => {

    return  <div className="flex flex-col">
    <div className="rounded-md border border-1 border-[#2C2E33] mt-2">
    <div className="flex">
      <div className="w-full px-3 py-3 border border-r-1 border-b-1 border-t-0 border-l-0 border-[#2C2E33] bg-[#0F1011]">
        Header name
      </div>
      <div className="w-full px-3 py-3 border border-b-1 border-r-0 border-t-0 border-l-0 border-[#2C2E33] bg-[#0F1011]">
        Value
      </div>
    </div>
    {headers.map((header, i) => <div className="flex">
              <input
                  className="w-full px-3 focus:outline-none active:outline-none py-2 border border-r-1 border-b-1 border-t-0 border-l-0 border-[#2C2E33] bg-[#0F1011]"
                  id="outlined-required"
                  value={header[0]}
                  onChange={(e) => {
                    const newHeaders = [...headers];
                    newHeaders[i][0] = e.target.value;
                    setHeaders(newHeaders);
                }}
              />
              <input
                  className="w-full px-3 focus:outline-none active:outline-none py-2 border border-r-1 border-b-1 border-t-0 border-l-0 border-[#2C2E33] bg-[#0F1011]"
                  id="outlined-required"
                  value={header[1]}
                  onChange={(e) => {
                    const newHeaders = [...headers];
                    newHeaders[i][1] = e.target.value;
                    setHeaders(newHeaders);
                }}
              />          
          </div>)}
  </div>
    <div
      className="py-2 px-3 bg-[#2C2E33] text-white self-end mt-2 cursor-pointer rounded-md"
      onClick={() => {
        setHeaders([...headers, ["", ""]]);
      }}
    >Add Headers</div>
    </div>
}

export default AddAPIHeaders;