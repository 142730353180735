import axios from 'axios'
import { config } from "../config";

let dev = config.dev
let dburl1 = dev === false ? "https://server-v3-7qxc7hlaka-uc.a.run.app" : "http://localhost:3000"
let seq_agent_url = dev === false ? "https://sequencing-agent-yml-7qxc7hlaka-uc.a.run.app" : "http://localhost:8086"
// 
axios.defaults.withCredentials = true

export const loginUser = (email, password, rememberMe) => async (dispatch) => {
  try {
    dispatch({
      type: "loginRequest",
    });

    // Sending the request to the server
    const response = await axios.post(
      `${dburl1}/auth/login`,
      { email, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    const { accessToken, user, isNew } = response.data;
    
    localStorage.setItem('isNew', isNew)

    // If the user has selected remember me, we will save the token in local storage
    if (rememberMe) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('key', response.data.key); //encode key and then store if required
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('isAuthenticated', true);
    } else {
      // If the user has not selected remember me, we will save the token in session storage
      sessionStorage.setItem('accessToken', accessToken);
      sessionStorage.setItem('key', response.data.key);
      sessionStorage.setItem('user', JSON.stringify(user));
      sessionStorage.setItem('isAuthenticated', true);
    }

    dispatch({
      type: "loginSuccess",
      payload: response.data,
    });
  } catch (error) {

    dispatch({
      type: "loginFailure",
      payload: error.message,
    });
  }
};

export const requestNames = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: 'requestNamesRequest'
    })

    const response = await axios.post(`${seq_agent_url}/generate_request_names`, {
      userid: userId
    }, {
      withCredentials: true
    })
    localStorage.setItem('requestNames', response.data)
    dispatch({
      type: 'requestNamesSuccess',
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: 'requestNamesFailure',
      payload: error.message
    })
  }

}

export const checkAuthStatus = () => async (dispatch) => {
  try {
    const response = await axios.get(`${dburl1}/auth/check-auth`, { withCredentials: true });
    dispatch({ type: 'LOGIN_SUCCESS', payload: response.data.user });
  } catch (error) {
    dispatch({ type: 'LOGIN_FAIL' });
  }
};

export const refreshAccessToken = async (dispatch) => {
  try {

    dispatch({
      type: "refreshTokenRequest",
    });

    // Sending the request to the server
    const response = await axios.post(
      `${dburl1}/auth/refresh-token`,
      {},
      {
        withCredentials: true,
      }
    );

    const { accessToken } = response.data;

    localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: "refreshTokenSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "refreshTokenFailure",
      payload: error.message,
    });
  }
};

export const loginUserSuccess = (data) => ({
  type: "loginSuccess",
  payload: data,
});

// export const googleSSO = (token, rememberMe) => async (dispatch) => {
//   try {
//     dispatch({
//       type: "googleLoginRequest"
//     });

//     // 
//     const response = await axios.post(`${dburl1}/auth/google-login`, {
//       googleToken: token,
//     });

//     localStorage.setItem('isNew', response.data.isNew)

//      // If the user has selected remember me, we will save the token in local storage
//      if (rememberMe) {
//       localStorage.setItem('user', JSON.stringify(response.data.user));
//       localStorage.setItem('isAuthenticated', true);
//       localStorage.setItem('accessToken', response.data.accessToken);
//       localStorage.setItem('key', response.data.key); //encode key and then store if required
//     } else {
//       // If the user has not selected remember me, we will save the token in session storage
//       sessionStorage.setItem('user', JSON.stringify(response.data.user));
//       sessionStorage.setItem('isAuthenticated', true);
//       sessionStorage.setItem('accessToken', response.data.accessToken);
//       sessionStorage.setItem('key', response.data.key); //encode key and then store if required
//     }

//     dispatch({
//       type: "googleLoginSuccess",
//       payload: response.data,
//     });
//   }
//   catch (error) {
//     dispatch({
//       type: "googleLoginFailure",
//       payload: error.response.data.message,
//     });
//   }
// }

export const googleSSO = (token) => async (dispatch) => {
  try {
    const response = await axios.post('/auth/google-sso', { token }, { withCredentials: true });
    dispatch({ type: 'LOGIN_SUCCESS', payload: response.data.user });
  } catch (error) {
    dispatch({ type: 'LOGIN_FAIL' });
  }
};

export const validateGoogleToken = (googleToken) => async (dispatch) => {
  try {
    dispatch({ type: "googleTokenValidationRequest" });

    const response = await axios.post(`${dburl1}/auth/validate-google-token`, {
      googleToken: googleToken,
    });

    dispatch({
      type: "googleTokenValidationSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "googleTokenValidationFailure",
      payload: error.message,
    });
  }
}
export const logoutUser = (email, password, rememberMe) => async (dispatch) => {
  try {
    dispatch({
      type: "logoutUserRequest",
    });

    dispatch({
      type: "logoutUserSuccess",
    });
  } catch (error) {
    dispatch({
      type: "logoutUserFailure",
      payload: error.message,
    });
  }
};

export const githubSSO = (code, rememberMe) => async (dispatch) => {
  try {
    dispatch({
      type: "githubLoginRequest"
    });

    // 
    const { data } = await axios.get(`${dburl1}/auth/github-login/?code=${code}`);

    localStorage.setItem('isNew', data.isNew)

     // If the user has selected remember me, we will save the token in local storage
     if (rememberMe) {
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('key', data.key); //encode key and then store if required
    } else {
      // If the user has not selected remember me, we will save the token in session storage
      sessionStorage.setItem('user', JSON.stringify(data.user));
      sessionStorage.setItem('isAuthenticated', true);
      sessionStorage.setItem('accessToken', data.accessToken);
      sessionStorage.setItem('key', data.key); //encode key and then store if required
    }

    dispatch({
      type: "githubLoginSuccess",
      payload: data,
    });
  }
  catch (error) {
    dispatch({
      type: "githubLoginFailure",
      payload: error.response.data.message,
    });
  }
}

export const resetPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: "updatePasswordRequest"
    });

    // 
    const { data } = await axios.post(`${dburl1}/auth/reset-password`, { email });
    // console.log(data)
    dispatch({
      type: "updatePasswordSuccess",
      payload: data,
    });
  }
  catch (error) {
    dispatch({
      type: "updatePasswordFailure",
      payload: error.response.data.message,
    });
  }
}

export const registerUser = (name, email, password, city, avatar) => async (dispatch) => {

};

export const sendOrgDetails = (orgDetails) => async (dispatch) => {
  try {
    dispatch({
      type: "sendOrgDetailsRequest"
    });

    // 
    const { data } = await axios.post(`${dburl1}/auth/org-details`, orgDetails, {
      withCredentials: true
    });
    // console.log(data)
    dispatch({
      type: "sendOrgDetailsSuccess",
      payload: data,
    });
  }
  catch (error) {
    dispatch({
      type: "sendOrgDetailsFailure",
      payload: error.response.data.message,
    });
  }
}