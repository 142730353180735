import Logo from '../../icons/devzery-logo-icon.svg'
import { useTranslation } from 'react-i18next'

const OnboardingNav = ({active}) => {
    const {t} = useTranslation();
    return <div className="flex justify-between py-8 px-16 w-full items-center">
    <div className=""><img src={Logo} alt="" /></div>
    <div className="flex justify-end items-center text-sm">
        <div className="flex items-center mr-8"><span className={"mr-2 rounded-full text-xs w-[18px] h-[18px] block flex items-center justify-center " + (active > 1 ? "bg-[#31744A]" : active === 1 ? "bg-[#F257A0]" : "bg-[#787B95] text-[#E7E7E7]")}>1</span>{t('pageStatus.integrate_sdk')}</div>
        <div className="flex items-center mr-8"><span className={"mr-2 rounded-full text-xs w-[18px] h-[18px] block flex items-center justify-center " + (active > 2 ? "bg-[#31744A]" : active === 2 ? "bg-[#F257A0]" : "bg-[#787B95] text-[#E7E7E7]")}>2</span>{t('pageStatus.collectAPIs')}</div>
        <div className="flex items-center mr-8"><span className={"mr-2 rounded-full text-xs w-[18px] h-[18px] block flex items-center justify-center " + (active > 3 ? "bg-[#31744A]" : active === 3 ? "bg-[#F257A0]" : "bg-[#787B95] text-[#E7E7E7]")}>3</span>{t('pageStatus.verifyAPIs')}</div>
        <div className="flex items-center"><span className={"mr-2 rounded-full text-xs w-[18px] h-[18px] block flex items-center justify-center " + (active > 4 ? "bg-[#31744A]" : active === 4 ? "bg-[#F257A0]" : "bg-[#787B95] text-[#E7E7E7]")}>4</span>{t('pageStatus.swagger_doc')}</div>
    </div>
</div>
}

export default OnboardingNav