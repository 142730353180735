const SubHeader = ({ onTabChange, activeTab }) => {
    const tabs = [
        { id: 'workspace', label: 'Workspace' },
        { id: 'profile', label: 'Profile' },
        { id: 'team', label: 'Team' },
        { id: 'notification', label: 'Notification' },
        { id: 'support', label: 'Support' }
    ];
    return (
        <div className="w-full px-6 py-2 border-b border-[#2C2E33] bg-background z-10">
            <div className="flex items-center gap-[16px] h-full">
                {tabs.map((tab) => (
                    <div
                        className={"w-[94px] rounded-md py-1 border text-center cursor-pointer " + (activeTab === tab.id ? 'bg-[#D85C93] border-[#E27AAB] text-white' : 'border-[#2C2E33] text-[#B0B0B0]')}
                        key={tab.id}
                        onClick={() => onTabChange(tab.id)}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SubHeader;