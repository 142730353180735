import React, { useState } from 'react'
import './IndividualBug.css'
import { Typography } from '@mui/material'
import ArrowLeft from '../../icons/arrow-left.svg'
import Clock from '../../icons/clock.svg'
import Calendar from '../../icons/calendar.svg'
import APISidebar from '../APISidebar/APISidebar'
import ReqResBody from '../ReqResBody/ReqResBody'
import Layout from '../Pagelayout/Layout';

/*

IndividualBug component that renders the individual bug page.
The IndividualBug component displays the details of the individual bug.
The component uses the ReqResBody component to display the request, response, and actual response.
The component uses the APISidebar component to display the list of APIs.

*/
const IndividualBug = () => {
    const apis = [
        {
            id: "1",
            name: "2",
            assertions: 3,
            passed: "Passed",
            failed: 1,
        },
        {
            id: "1",
            name: "2",
            assertions: 3,
            passed: "Passed",
            failed: 1,
        }
    ]

    const [testModal, setTestModal] = useState(false)
    const [headerModal, setHeaderModal] = useState(false)

    const handleTestOpen = () => {
        setTestModal(true)
    }
    const handleTestClose = () => {
        setTestModal(false)
    }
    const handleHeaderOpen = () => {
        setHeaderModal(true)
    }
    const handleHeaderClose = () => {
        setHeaderModal(false)
    }

    return (
        <Layout>
            {/* <div className='individual-bug-page'>
                <div className="individual-bug-container"> */}
                    <div className="back-arrow">
                        <div className="arrow-icon">
                            <img src={ArrowLeft} alt="arrow-left" />
                        </div>
                        <div className="time-info">
                            <div className="calendar-div">
                                <img className='time-info-icon' src={Calendar} />
                                <p>29 Mar</p>
                            </div>
                            <div className="clock-div">
                                <img className='time-info-icon' src={Clock} />
                                <p>10:00 AM</p>
                            </div>
                        </div>
                    </div>
                    <div className="bug-name-assertion">
                        <div className="bug-number">
                            <p>123456</p>
                        </div>
                        <Typography variant='h6'>Create and Apply Stackable Coupon (100 rupees off) - Specific Collection Entitlement</Typography>
                    </div>
                    <div className="bug-endpoint">
                        <Typography variant='h6'>Bug End Point</Typography>
                        <div className="bug-endpoint-container">
                            <div className="method">
                                POST
                            </div>
                            <div className="bug-endpoint-url">
                                <p>https://bug.coupons.com/v1/coupon</p>
                            </div>
                        </div>
                    </div>
                    <div className="req-res-actual">
                        <ReqResBody
                            title={'Request'}
                        />
                        <ReqResBody
                            title={'Response'}
                        />
                        <ReqResBody
                            title={'Actual Response'}
                        />
                    </div>

                {/* </div> */}
                <APISidebar
                    isBugs={true}
                />
            {/* </div> */}
        </Layout>
    )
}

export default IndividualBug



