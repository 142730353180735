import React from 'react'
import './EndpointCoverage.css'
import PieChart from '../../icons/pie-chart.png'
import { Typography } from '@mui/material'
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import TestStatus from './TestStatus/TestStatus';
import { LuRefreshCcw } from "react-icons/lu";

Chart.register(ArcElement, Tooltip, Legend, Title); // Create a chart with the necessary elements

/*

The EndpointCoverage component renders the test coverage statistics for the endpoint.
It displays the number of tests passed and failed in a doughnut chart.
It uses the Doughnut component from react-chartjs-2 to render the chart.
The component also displays the number of tests passed and failed.
The component takes in the passed and fail props to render the test coverage statistics.

*/
const EndpointCoverage = ({ passed, fail }) => {

    const empty = passed >= 0 && fail >= 0 ? false : true;

    // Create a data object with the passed and failed tests
    const data = {
        labels: ['Tests Passed', 'Tests Failed'],
        datasets: [{
            data: [passed, fail],
            backgroundColor: [
                '#4ADE80',
                '#F87171',
            ],
            borderColor: [
                '#4ADE80',
                '#F87171',
            ],
            hoverOffset: 4,
        }],
        radius: 60,

    };

    // Chart design options
    const chartOptions = data !== null ? {
        cutout: '95%',
        plugins: {
            tooltip: {
                backgroundColor: (context) => {
                    const label = context.tooltip.dataPoints[0].label || '';
                    switch (label) {
                        case 'Tests Passed':
                            return '#533543';
                        case 'Tests Failed':
                            return '#3B4056';
                        default:
                            return '#fff';
                    }
                },
                displayColors: false,
                titleColor: (context) => {
                    const label = context.tooltip.dataPoints[0].label || '';
                    switch (label) {
                        case 'Tests Passed':
                            return '#F37BB3';
                        case 'Tests Failed':
                            return '#96A7FF';
                        default:
                            return '#fff';
                    }
                },
                callbacks: {
                    label: function (context) {
                        const value = context.formattedValue;
                        const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
                        const percentage = Math.round((value / total) * 100);
                        return `${percentage}%`;
                    }
                }
            },
            legend: {
                display: false, // Hides the labels on top of the chart
            },
        },
        maintainAspectRatio: false,
        elements: {
            arc: {
                hoverRadius: 0, // Adjust this value to control the size of the label square when hovering
                borderWidth: 0.01, // Removes the border of the label square
            }
        }
    } : null;


    return (
        <div className="bg-[#0F1011] rounded-md border border-[#2C2E33] p-4 w-full">
            <div className='flex justify-between items-center'>
                <span className='text-[#E27AAB]'>
                    Test Coverage
                </span>
                <LuRefreshCcw className='text-[#B0B0B0]' />
            </div>
            {passed >= 0 && fail >= 0 ? <div className='relative my-12'>
                <Doughnut
                    height="150px"
                    width="150px"
                    data={data}
                    options={chartOptions}
                />
                <div className="absolute top-[40%] font-light text-[#BA76FF] left-1/2 -translate-x-1/2 mb-3 text-center">Total tests <div className='text-white font-regular text-lg'>{passed + fail}</div></div>
            </div> :
                <>
                    <img src={PieChart} alt='Pie Chart' className='httpCoverage-image' />
                    <Typography variant='h6'>
                        Whoops! No data<br></br> available at the moment.
                    </Typography>
                </>
            }
            {data !== null && passed >= 0 && fail >= 0 ? <div>
                <TestStatus
                    status={"Passed"}
                    val={Math.round((passed * 100) / (passed + fail))}
                />
                <TestStatus
                    status={"Failed"}
                    val={Math.round((fail * 100) / (passed + fail))}
                />
            </div> : null}
        </div>
    )
}

export default EndpointCoverage
