import React from 'react'
import './Assertion.css'
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

/*

The Assertion component renders the assertions on the individual test case page.
It displays the assertion ID, title, and status of the assertion.
The component uses the Link component from react-router-dom to navigate to the assertion details page.


*/
const Assertion = ({parentId,title,assertion,pass,isBugs}) => {
    let name=isBugs?'ageing-bugs-container':'assertion-container'
    let status= pass ? 'passed' : 'failed'
    return (
       <Link className='assertion-link' to={`/api/${parentId}`}>
        <div className={name}>
            <div className={'capitalize px-2 py-1 rounded-sm ' + (status === 'passed' ? "bg-[#31744A]" : "bg-[#633238]")}>
                {status}
            </div>
            <p>{title}</p>
        </div>
       </Link>
    )
}

export default Assertion
