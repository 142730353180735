import CircularProgress from '@mui/material/CircularProgress'
import Lottie from 'lottie-react'
import loadingAnimation from '../../../../icons/Onboarding Loading Animation.json'
import { useAuth } from "../../../../AuthContext";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

const Loader = ({ type, setModalOpen, apiNames, setApiCollected }) => {
    // console.log("Loader", type)
  const { logout } = useAuth();

    const handleLogout = async () => {
        await logout();
      }

    return <div className="w-full flex flex-col items-center justify-center p-24 gap-8 relative h-[80vh] overflow-hidden">
        {type === "Collecting Unique APIs" ? <div onClick={()=> setModalOpen(true)} className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] absolute top-4 right-4 rounded min-w-fit px-4 py-0.5 cursor-pointer">Add Product Specifications</div> : null}
        <div className="absolute bottom-0 right-4 flex gap-2">
            <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-0.5 cursor-pointer">Need Help?</div>
            <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-0.5 cursor-pointer" onClick={handleLogout}>Logout</div>
        </div>
        <Lottie
            options={{
                loop: true,
                autoplay: true,
                animationData: loadingAnimation,
                rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                }
            }}
            height={200}
            width={200}
        />
        {type === "Collecting Unique APIs" ? <div className='max-w-[50vw] text-wrap text-center'>
            Start using your product to help us to collect at least 5 unique APIs and generate Swagger documentation.
            To know how to use Devzery - <span className="text-[#F37BB3] font-semibold"><a href="https://www.devzery.com/sdk" target='_blank'>click here</a></span>
        </div> : null}
        {type !== "Collecting Unique APIs" ? <div className='flex items-center gap-4'>
            <span className='text-[#E45799]'>
                <CircularProgress size={24} color="inherit" />
            </span>
            {type}
        </div> : null}
        {type === "Collecting Unique APIs" && apiNames ? <div className="flex items-center gap-2">
            <IoCheckmarkCircleOutline className="text-[#4ADE80]" />
            API Collection complete!
        </div>: <div className='flex items-center gap-4'>
            <span className='text-[#E45799]'>
                <CircularProgress size={24} color="inherit" />
            </span>
            {type}
        </div>}
        {type === "Collecting Unique APIs" ? <div onClick={()=> {
            if (apiNames){
                setApiCollected(false)
            }
        }} className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-1 cursor-pointer">Refresh to update API collection status</div> : null}
    </div>
}

export default Loader;