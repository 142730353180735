import SettingsHeading from '../Components/SettingsHeading';
import { Panel } from 'react-resizable-panels';
import Button from '../../../components/Button/Button';

const SupportSettings = () => {
    return (
        <div>
            <Panel>
                <SettingsHeading variant='support' />
            </Panel>
            <Panel>
                <div className="flex flex-col gap-[12px]">
                    <div className="flex">
                    <p className="text-text-primary font-bold text-h6">
                        Message
                    </p><p className='text-text-accent text-h6'>*</p>
                    </div>
                    <div className="w-full flex gap-[50px]">
                        <div className="relative w-[924px] h-[287px]">
                            <textarea
                                type="text"
                                placeholder="Enter Query Here"
                                className="w-full h-full px-[14px] pr-[40px] py-[10px] rounded-[6px] bg-[#0F1011] border border-[#2C2E33] text-text-primary align-top resize-none transition-colors duration-200 focus:outline-none focus:border-primary"
                            />
                        </div>
                    </div>
                    <Button variant="tertiary" size="medium" className='mt-[24px] h-[36px]'>
                        Send
                    </Button>
                </div>
            </Panel>

        </div>
    );
};

export default SupportSettings;