import { LuTrash } from "react-icons/lu";

const HeaderRow = ({ header, handleHeaderDelete, ind }) => {
    
    return <div className="grid grid-cols-12 border-b border-[#2C2E33]">
        <div className="col-span-1 flex items-center justify-center"><input
            // style={{ marginRight: "5px" }}
            type="checkbox"
            id="apiSelected"
            name="apiSelected"
        /></div>
        <div className="col-span-4 px-3 py-2 border-x border-[#2C2E33]">{header[0]}</div>
        <div className="col-span-6 px-3 py-2 text-wrap break-words w-full overflow-x-auto no-scrollbar">{header[1]}</div>
        <div onClick={() => handleHeaderDelete(ind)} className="col-span-1 text-[#494949] cursor-pointer flex items-center justify-center border-l border-[#2C2E33] cursor-pointer" >
            <span className="cursor-pointer rounded-md p-1.5 hover:text-[#F87171] hover:bg-[#1D1E20]">
                <LuTrash />
            </span>
        </div>
    </div>
}

export default HeaderRow;