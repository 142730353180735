import React, { useState } from "react";
import PlusIcon from "../../../icons/plus-icon.svg";


const APISequence = ({ allApis, setCurrentApi, addedApis }) => {
    const [apiCount, setApiCount] = useState(1);

  const handleApiSelection = (apiID, index) => {
    const selected = allApis.filter((api) => api.api.id === apiID);
    setCurrentApi(selected[0]);
    addedApis[index] = selected[0]
  };

  const renderApiSequence = () => {
    const apiSequence = [];
    for (let i = 0; i < apiCount; i++) {
      apiSequence.push(
        <div className="mt-4 w-full">
          <select
            value={addedApis[i] ? addedApis[i].id : 'default'}
            className={"px-4 py-3 bg-[#0F1011] no-scrollbar rounded-md w-full text-white outline-none focus:outline-none border " + (i === apiCount - 1? " border-[#F37BB3]": " border-[#2C2E33]")}
            onChange={(e) => handleApiSelection(e.target.value, i)}
          >
            <option value='default' disabled="disabled">Select an API</option>
            {allApis?.map((api) => (
              <option value={api.api.id}>{api.api.name}</option>
            ))}
          </select>
        </div>
      );
    }
    return apiSequence;
  }

  return (
    <React.Fragment>
     {renderApiSequence()}
      <div className="p-3 py-1.5 cursor-pointer bg-[#0F1011] border border-[#35383E] text-[#B0B0B0] rounded-md max-w-fit flex items-center gap-2 mt-4" onClick={() => setApiCount(apiCount +1)}>
        <img src={PlusIcon} alt="plus-icon" /> Add API
      </div>
    </React.Fragment>
  );
};

export default APISequence;
