import React, { useState } from 'react';
import './ReqResBody.css';
import { Typography, Alert, Snackbar } from '@mui/material';
import EditIcon from '../../icons/edit-icon.svg';
import CopyIcon from '../../icons/copy-icon.svg';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

const ReqResBody = ({ title, string, status }) => {
    // Initialize parsedString with proper type checking
    const getParsedString = (input) => {
        if (input === null || input === undefined) {
            return "{}";
        }
        if (typeof input === 'string') {
            try {
                // If it's already a JSON string, parse it to ensure valid JSON
                JSON.parse(input);
                return input;
            } catch {
                return JSON.stringify({}, null, 2);
            }
        }
        // If it's an object, stringify it
        return JSON.stringify(input, null, 2);
    };

    const parsedString = getParsedString(string);
    const [text, setText] = useState(parsedString);
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(parsedString);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    return (
        <div className='req-res-container'>
            <div className="req-res-top">
                <Typography variant='h6'>{title}</Typography>
                <div className="edit-copy">
                    <img
                        onClick={copyToClipboard}
                        className='copy-icon'
                        src={CopyIcon} 
                        alt="copy icon" 
                    />
                </div>
            </div>
            <div className="req-res-bottom">
                {title !== 'Request' && (
                    <div className={`rounded-sm px-2 py-1 self-end justify-self-center ${
                        status < 300 ? "bg-[#31744A]" : "bg-[#633238]"
                    }`}>
                        {status}
                    </div>
                )}
                <div className="req-res-body">
                    <JSONPretty
                        style={{
                            overflowY: 'auto',
                            height: "100%"
                        }}
                        id="json-pretty"
                        themeClassName='custom-json-pretty'
                        keyStyle='color: #E45799;'
                        data={text}
                    />
                </div>
            </div>
            <Snackbar
                open={copied}
                autoHideDuration={1000}
                onClose={() => setCopied(false)}
            >
                <Alert severity="success">Copied to clipboard</Alert>
            </Snackbar>
        </div>
    );
};

export default ReqResBody;
