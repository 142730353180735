import { createReducer } from '@reduxjs/toolkit';

// Initial state for the test case reducer
const initialState = {
    loading: false,
    testCases: null,
    error: null,
    message: null,
    tempAPIs: null,
    testScripts: null,
    testSteps: null,
    tests: null
};

/*

The testCasesReducer is created with the help of the createReducer function from the redux toolkit.
The initial state for the test case reducer is defined.
The testCasesReducer handles actions related to fetching test cases.
The testCasesReducer handles actions related to running all tests.
Each action has a corresponding case in the testCasesReducer - request, success, and failure.

*/



export const testCasesReducer = createReducer(initialState, (builder) => {

    builder
    .addCase('fetchTCRequest', (state, action) => {
        state.loading = true;
    })
    .addCase('fetchTCSuccess', (state, action) => {
        state.loading = false;
        state.testCases = action.payload;
    })
    .addCase('fetchTCFailure', (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })
    .addCase('runAllTestsRequest',(state,action)=>{
        state.loading = true
    })
    .addCase('runAllTestsSuccess',(state,action)=>{
        state.loading = false
        state.message = action.payload
    })
    .addCase('runAllTestsFailure',(state,action)=>{
        state.loading = false
        state.error = action.payload
    })
    .addCase('tempAPIs',(state,action)=>{
        state.tempAPIs = action.payload
    })
    .addCase('fetchTestScriptsRequest',(state,action)=>{
        state.loading = true
    })
    .addCase('fetchTestScriptsSuccess',(state,action)=>{
        state.loading = false
        state.testScripts = action.payload
    })
    .addCase('fetchTestScriptsFailure',(state,action)=>{
        state.loading = false
        state.error = action.payload
    })
    .addCase('getTestStepsRequest',(state,action)=>{
        state.loading = true
    })
    .addCase('getTestStepsSuccess',(state,action)=>{
        state.loading = false
        state.testSteps = action.payload.data
    })
    .addCase('getTestStepsFailure',(state,action)=>{
        state.loading = false
        state.error = action.payload
    })
    .addCase('updateTC', (state, action) => {
        state.testCases = action.payload;
    })
    .addDefaultCase((state, action) => {
        return state;
    });
})

export const testsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TEST_DATA':
            return {
                ...state,
                tests: action.payload
            }
        default:
            return state;
    }
}
