import React, { useEffect, useState } from "react";
import "./IndividualTest.css";
import StopWatch from "../../icons/stopwatch.svg";
import Calendar from "../../icons/calendar.svg";
import { useParams } from "react-router";
import {
    addTestCaseAPI,
    fetchAllTC,
    executeIndividualTest
} from "../../Actions/tcActions";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Alert, Snackbar, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HiPlusSmall } from "react-icons/hi2";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { IoMdPlay, IoMdCloseCircleOutline } from "react-icons/io";
import { IoCheckmarkCircleOutline, IoEllipsisHorizontalSharp } from "react-icons/io5";
import { LiaClock } from "react-icons/lia";
import { FiPaperclip } from "react-icons/fi";
import { getMethodClasses } from "../../utils/ui";
import Layout from '../Pagelayout/Layout';
import { PiPlus, PiCopy } from "react-icons/pi";
import { LuTrash } from "react-icons/lu";
import { getResponseBody } from "../../Actions/tcActions";
import JSONPretty from "react-json-pretty";
import { useAuth } from "../../AuthContext";


/*


IndividualTest component that renders the individual test case page.
It uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
It uses the useEffect hook to fetch the test cases when the component mounts.
It renders the Sidebar component.
The Sidebar component displays the list of assertions for the selected API.
This component renders on the /test/:id route.


*/


const IndividualTest = () => {
    const { id } = useParams(); // Get the id from the URL
    const [modalOpen, setModalOpen] = useState(false);
    const [headers, setHeaders] = useState([["", ""], ["", ""], ["", ""]]);
    const [body, setBody] = useState(null);
    const [apiDetails, setApiDetails] = useState({
        name: null,
        method: "get",
        path: null,
        serverName: null,
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, testCases, testScripts } = useSelector((state) => state.testCases); // Get the test cases from the state
    const { loading: apiLoading, apis, responseBody, error } = useSelector((state) => state.apis); // Get the APIs from the state


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");


    const { apikey, user } = useAuth();


    useEffect(() => {
        dispatch(fetchAllTC(id)); // Fetch all test cases for the id
    }, [dispatch]);


    useEffect(() => {
        if (error) {
            // console.log("error", error);
            setAlertSeverity("error");
            setAlertMessage(`Error adding the API: ${error}`);
            setSnackbarOpen(true);
            dispatch({
                type: "clearError",
            });
        }
    }, [error]);


    let tcName = "";
    let tcNumber = 0;
    let tcDate = "";
    let tcTime = 0;
    let tcDuration = 0;
    let tcPass = null;
    let tcDescription = "";


    // Find the test case with the given id
    if (testCases) {
        testCases?.tests?.forEach((test) => {
            if (test?.id === id) {
                tcName = test?.title;
                tcNumber = test?.tcNumber;
                tcDate = test?.date;
                tcTime = test?.time;
                tcDuration = test?.duration;
                tcPass = test?.pass;
                tcDescription = testScripts.script.find(el => el.name === test.title)?.description
            }
        });
    }


    const handleTestAPI = () => {
        let formattedHeaders = {};
        headers.forEach((header) => {
            if (header[0] !== "" && header[1] !== "") {
                formattedHeaders[header[0]] = header[1];
            }
        });
        // console.log(headers, formattedHeaders, body);
        dispatch(
            getResponseBody({
                body: JSON.parse(body),
                headers: formattedHeaders,
                apiEndpoint: apiDetails.path,
                apiMethod: "GET",
                serverName: apiDetails.serverName,
            })
        );
    };


    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };


    let data = []; // Create an array to store the apis

    if (apis !== undefined && apis?.data !== undefined) {
        apis?.data?.forEach((api) => {
            data.push({
                apiId: api?.api?._id,
                apiTitle: api?.api?.title,
                assertions: api?.api?.assertions,
                pass: api?.api?.pass,
                // failed: api?.api?.fails.length,
                step: api?.api?.step,
                method: api?.api?.method,
                path: api?.api?.path,
            });
        });
        // data = data;
    }
    // var res = data.sort((a, b) => b.step-a.step)
    // // console.log(res.reverse());

    //   if (allAssertions?.length > 0) {
    //     // Filter the assertions based on the selected row
    //     assertions = allAssertions.filter(
    //       (assertion) => assertion?.apiId === data[selectedRow].apiId
    //     );
    //   }


    const handleAddTCAPI = () => {
        try {
            dispatch(addTestCaseAPI({
                tcName,
                api: {
                    name: apiDetails.name,
                    baseUrl: apiDetails.serverName,
                    endpoint: apiDetails.path,
                    method: apiDetails.method,
                    body: body ?? null,
                    headers: headers ?? null,
                    expectedStatus: 200,
                    response: responseBody?.data ?? {},
                },
                apikey
            }))
        } catch (error) {
            // On error
            setAlertSeverity("error");
            setAlertMessage("Failed to Add Test Case");
            setSnackbarOpen(true);
        } finally {
            setAlertSeverity("success");
            setAlertMessage("Successfully Added Test Case");
            setSnackbarOpen(true);
        }
    };


    const runTest = () => {
        if (testScripts?.script?.some(script => script.name === tcName)) {
            let script = testScripts?.script?.find(script => script.name === tcName);
            dispatch(executeIndividualTest(script, user?._id, apikey))
        }
    }


    useEffect(() => {
        if (headers[headers.length - 1][0] !== "" && headers[headers.length - 1][1].length === 1) {
            setHeaders([...headers, ["", ""]]);
        }
    }, [headers[headers.length - 1][1]])


    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setSnackbarOpen(true);
        setAlertSeverity("success");
        setAlertMessage("Copied to Clipboard");
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 1000);
    };


    const handleHeaderDelete = (index) => {
        let newHeaders = [...headers];
        newHeaders.splice(index, 1);
        setHeaders(newHeaders);
    }


    return (
        <Layout loading={loading || apiLoading}>
            <div className="col-span-9 text-white h-screen overflow-y-scroll no-scrollbar flex w-full">
                <div className="w-[20%] py-4 border-r border-[#2C2E33]">
                    <div className="flex gap-6 text items-center mb-8 bg-[#141516] px-4 py-2">
                        <MdOutlineArrowBackIos className="#B0B0B0 cursor-pointer" onClick={() => navigate(-1)} />
                        <span className="text-[#A0A0A0]">Test Case Details</span>
                    </div>
                    <div className="p-4">
                        <div className="text-white">Test Steps</div>
                        <div className="py-6 overflow-y-scroll max-h-[80vh] no-scrollbar">
                            {data?.length > 0 && data.sort((a, b) => a.step - b.step).map((api, index) => (
                                <div className="flex items-center text-[#A0A0A0] justify-between my-6 group cursor-pointer" onClick={() => navigate(`/api/${api.apiId}`)}>
                                    <div className="flex items-center gap-3">
                                        <MdOutlineArrowBackIos className="#B0B0B0 cursor-pointer rotate-180" />
                                        <span className={"rounded-full px-2 py-1 text-xs capitalize " + getMethodClasses(api?.method)}>{api?.method}</span>
                                        <div className="text-nowrap w-[120px] group-hover:w-[155px] overflow-x-scroll no-scrollbar">{api?.apiTitle}</div>
                                    </div>
                                    <div className="group-hover:hidden">{(api?.assertions?.status?.pass && api?.assertions?.response?.pass) ? <IoCheckmarkCircleOutline className="text-[#65DC8D]" size={18} /> : <IoMdCloseCircleOutline className="text-[#F87171]" size={18} />}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-[80%] px-16 py-12">
                    <div className="flex items-center justify-between">
                        <div className="bg-[#1D1E20] justify-self-start px-6 py-0.5 rounded border border-[#282C33]">{tcNumber}</div>
                        <div className="flex items-center gap-3 text-[#B0B0B0]">
                            <div className="flex gap-2 items-center rounded-md px-2 py-1 border border-[#2C2E33] cursor-pointer" onClick={() => setModalOpen(true)}>
                                <HiPlusSmall /> Add API
                            </div>
                            <div className="rounded-md px-3 py-1 border border-[#2C2E33]">Share</div>
                            <div onClick={runTest} className="rounded-md px-2 py-1 border bg-[#D85C93] border-[#E27AAB] text-white cursor-pointer flex items-center gap-2 text-white">
                                <IoMdPlay />
                                Run Test Case
                            </div>
                            <IoEllipsisHorizontalSharp size={20} />
                        </div>
                    </div>
                    <div className="my-12 text-[#A0A0A0]">
                        <div className="text-xl mb-6">Test Case - </div>
                        {tcDescription}
                    </div>
                    <div className="flex gap-3 text-[#A0A0A0] pb-16 mb-16 border-b border-[#282C33]">
                        <div className="flex gap-2 rounded-md border border-[#282C33] px-2.5 py-1 items-center">
                            <img src={Calendar} alt='calendar' />
                            {tcDate}
                        </div>
                        <div className="flex gap-2 rounded-md border border-[#282C33] px-2.5 py-1 items-center">
                            <LiaClock size={16} />
                            {tcTime}
                        </div>
                        <div className="flex gap-2 rounded-md border border-[#282C33] px-2.5 py-1 items-center">
                            <img src={StopWatch} alt='stopwatch' />
                            {(tcDuration) / 1000} secs
                        </div>
                        <div className={"px-2.5 py-1 rounded flex gap-2 items-center " + (tcPass ? "bg-[#112313]" : "bg-[#240F10]")}>
                            <div className={"w-[10px] h-[10px] rounded-full " + (tcPass ? "bg-[#65DC8D]" : "bg-[#F87171]")} />
                            <span className={tcPass ? "text-[#DFF9E7]" : "text-[#FECACA]"}>{tcPass ? "Passed" : "Failed"}</span>
                        </div>
                    </div>
                    <div className="relative">
                        <textarea className="rounded-md border border-[#282C33] bg-inherit p-4 min-h-[25vh] w-full focus:outline-none active:outline-none" placeholder="Leave a comment..." />
                        <FiPaperclip className="absolute bottom-6 right-6 cursor-pointer" size={16} />
                    </div>
                    <div className="rounded-md border border-[#282C33] px-2.5 py-0.5 mt-3 cursor-pointer w-max">Save</div>
                </div>
            </div>
            {/* </div> */}
            <Dialog
                maxWidth="85vw"
                open={modalOpen}
                PaperProps={{
                    style: {
                        minHeight: "fit",
                        width: "85vw",
                        backgroundColor: "#0F1011",
                        color: "#fff",
                        boxShadow: "none",
                        borderRadius: "12px",
                        border: "1px solid #2C2E33",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                        "&::-webkit-scrollbar": {
                            display: "none",
                        }
                    },
                }}
            >
                <div>
                    <div className="w-full p-6">
                        <div className="flex justify-between items-center mb-8">
                            <div className="text-lg">Add API</div>
                            <div
                                onClick={() => setModalOpen(false)}
                                className="text-white cursor-pointer"
                            >
                                <PiPlus className="rotate-45" size={14} />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                            <input className="bg-inherit rounded-md border border-[#2C2E33] p-3 focus:outline-none active:outline-none" placeholder="Enter the API name" value={apiDetails.name} onChange={(e) => setApiDetails({ ...apiDetails, name: e.target.value })} />
                            <input className="bg-inherit rounded-md border border-[#2C2E33] p-3 focus:outline-none active:outline-none" placeholder="Enter the server name" value={apiDetails.serverName} onChange={(e) => setApiDetails({ ...apiDetails, serverName: e.target.value })} />
                            <div className="w-full rounded-md border border-[#2C2E33] py-4 flex items-center col-span-2">
                                <div className="px-4 border-r border-[#2C2E33]">
                                    <select
                                        defaultValue="get"
                                        onChange={(e) => setApiDetails({ ...apiDetails, method: e.target.value })}
                                        name="api-method-select"
                                        className={"rounded-full px-2 w-[70px] py-1 text-xs outline-none active:outline-none " + getMethodClasses(apiDetails.method)}
                                        id=""
                                    >
                                        <option value="post">POST</option>
                                        <option value="get">GET</option>
                                        <option value="put">PUT</option>
                                        <option value="delete">DELETE</option>
                                        <option value="patch" >PATCH</option>
                                    </select>
                                </div>
                                <input value={apiDetails.path} onChange={(e) => setApiDetails({ ...apiDetails, path: e.target.value })} className="px-4 bg-inherit w-full focus:outline-none active:outline-none" placeholder="Enter API endpoint" />
                            </div>
                            <div className="rounded-md border border-[#2C2E33] text-[#A0A0A0] col-span-2">
                                <div className="border-b border-[#2C2E33] ">
                                    <div className={"px-6 py-2 cursor-pointer border-b-2 border-[#E27AAB] text-[#D9D9D9] w-max"}>Headers</div>
                                </div>
                                <div className="max-h-[25vh] overflow-y-auto no-scrollbar">
                                    <div className="grid grid-cols-12 border-b border-[#2C2E33]">
                                        <div className="col-span-1" />
                                        <div className="col-span-4 px-3 py-2 border-x border-[#2C2E33]">Key</div>
                                        <div className="col-span-7 px-3 py-2">Value</div>
                                    </div>
                                    {headers.map((header, index) => <div className="grid grid-cols-12 border-b border-[#2C2E33]">
                                        <div className="col-span-1" />
                                        <input value={header[0]} onChange={(e) => {
                                            const newHeaders = [...headers];
                                            newHeaders[index][0] = e.target.value;
                                            setHeaders(newHeaders);
                                        }} className="col-span-4 bg-inherit px-3 py-2 border-x border-[#2C2E33]" />
                                        <input value={header[1]} onChange={(e) => {
                                            const newHeaders = [...headers];
                                            newHeaders[index][1] = e.target.value;
                                            setHeaders(newHeaders);
                                        }} className="col-span-6 bg-inherit px-3 py-2" />
                                        <div onClick={headers.length === 1 ? () =>  console.log('well') : () => handleHeaderDelete(index)} className="col-span-1 text-[#494949] flex items-center justify-center border-l border-[#2C2E33]" >
                                            <span className={headers.length >1 ? "cursor-pointer rounded-md p-1.5 hover:text-[#F87171] hover:bg-[#1D1E20]" : ""}>
                                                <LuTrash />
                                            </span>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                            <div className="rounded-md border border-[#2C2E33] p-2">
                                <div className="flex justify-between items-center">
                                    <div className={"px-4 py-1 cursor-pointer rounded-md border border-[#2C2E33] text-[#A0A0A0]"}>Request</div>
                                    <PiCopy size={16} className="text-[#494949] cursor-pointer" onClick={() => copyToClipboard(body ?? {})} />
                                </div>
                                <textarea value={body} onChange={(e) => setBody(e.target.value)} placeholder="Add request body" className="bg-inherit p-2 w-full h-[25vh] overflow-y-auto no-scrollbar text-[#B0B0B0] focus:outline-none active:outline-none" />
                            </div>
                            <div className="rounded-md border border-[#2C2E33] p-2">
                                <div className="flex justify-between items-center">
                                    <div className={"px-4 py-1 cursor-pointer rounded-md border border-[#2C2E33] text-[#A0A0A0]"}>Response</div>
                                    <PiCopy size={16} className="text-[#494949] cursor-pointer" onClick={() => copyToClipboard(responseBody?.data ?? {})} />
                                </div>
                                <div className="h-[25vh] overflow-y-auto no-scrollbar text-[#B0B0B0] p-2">{responseBody ? (
                                    <JSONPretty
                                        id="json-pretty"
                                        themeClassName="text-sm"
                                        keyStyle="color: #E45799;"
                                        data={JSON.stringify(
                                            responseBody?.data ?? { message: "hi" }
                                        )}
                                    ></JSONPretty>
                                ) : (
                                    "Test the API to see the response..."
                                )}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between text-sm px-12 py-4 border-t border-[#2C2E33] items-center mt-6">
                        <Button
                            sx={{
                                borderRadius: "6px",
                                backgroundColor: "#D9509B",
                                border: "1px solid #FF86BE",
                                padding: "6px 16px",
                                ":hover": {
                                    backgroundColor: "#CC3F8D",
                                },
                                color: "white",
                                textTransform: "unset",
                                fontWeight: "400",
                            }}
                            onClick={handleTestAPI}
                            className="cursor-pointer rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] px-4 py-1.5"
                        >
                            Test the API
                        </Button>
                        <div className="flex text-sm">
                            <div
                                onClick={() => setModalOpen(false)}
                                className="cursor-pointer mr-3 rounded-md bg-[#2D2F37] border border-1 border-[#35383E] px-4 py-1.5"
                            >
                                Cancel
                            </div>
                            <Button
                                sx={{
                                    "&.Mui-disabled": {
                                        background: "#222222",
                                        color: "white",
                                        border: "1px solid #222222",
                                    },
                                    borderRadius: "6px",
                                    backgroundColor: "#D9509B",
                                    border: "1px solid #FF86BE",
                                    padding: "6px 16px",
                                    ":hover": {
                                        backgroundColor: "#CC3F8D",
                                    },
                                    color: "white",
                                    textTransform: "unset",
                                    fontWeight: "400",
                                }}
                                type="submit"
                                {...(responseBody
                                    ? { disabled: false }
                                    : { disabled: true })}
                                className="cursor-pointer rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] px-4 py-1.5"
                                onClick={handleAddTCAPI}
                            >
                                Add Details
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={alertSeverity}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Layout >
    );
};


export default IndividualTest;