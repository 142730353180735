import React from 'react';
import { Typography } from '@mui/material';
import PieChart from '../../icons/pie-chart.png';
import PropTypes from 'prop-types';
import './EmptyState.css';

const EmptyState = ({ image = PieChart, altText = 'Coverage Chart', message, className }) => {
  return (
    <div className={`empty-state ${className || ''}`}>
      <img src={image} alt={altText} className='empty-state-image' />
      <Typography variant='h6' className='empty-state-message'>
        {message}
      </Typography>
    </div>
  );
};

EmptyState.propTypes = {
  image: PropTypes.string.isRequired, // Path to the image
  altText: PropTypes.string.isRequired, // Alt text for the image
  message: PropTypes.node.isRequired, // Message (supports HTML like <br />)
  className: PropTypes.string, // Additional styles (optional)
};

export default EmptyState;