import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './Reducers/userReducer';
import { testCasesReducer } from './Reducers/tcReducer';
import { apiReducer } from './Reducers/apiReducer';
import { assertionReducer } from './Reducers/assertionReducer';
import { sequencingReducer } from './Reducers/sequencingReducer';
import { testsReducer } from './Reducers/tcReducer';

const initialState = {}

/*

Redux store is created with the help of configureStore function from redux toolkit.
The store is created with the help of reducers from the Reducers folder.
The initial state is an empty object.
The store is exported.

*/

const store = configureStore({
   reducer:{
        user:userReducer,
        testCases:testCasesReducer,
        apis:apiReducer,
        assertions:assertionReducer,
        sequence: sequencingReducer,
        test: testsReducer
   },
    preloadedState: initialState
});

export default store;