import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux'
import './IssuesTableDropdown.css'
import { Dialog } from '@mui/material'
import EditIssueForm from '../EditIssueForm/EditIssueForm'
import Run from '../../../icons/caret-forward.svg'
import Stop from '../../../icons/stop-outline.svg'
import Ignore from '../../../icons/ignore.svg'
import Delete from '../../../icons/delete.svg'
import More from '../../../icons/more.svg'
import { executeIndividualTest, generateChainandExecute } from '../../../Actions/tcActions'
import Loader from '../../Loader/Loader';
import { useAuth } from '../../../AuthContext';
/*

IssuesTableDropdown component that renders the dropdown menu for the issues table.
The component uses the EditIssueForm component to edit the issue details.
The component uses the ITEM_HEIGHT constant to set the height of the menu items.

*/
const ITEM_HEIGHT = 48;

const IssuesTableDropdown = ({ id, prodId,editDetails, bug, test }) => {



    const [openDialog, setOpenDialog] = useState(false)
    // const userId = useSelector((state) => state.user.user?._id)
    const { user, apikey } = useAuth();
    const dispatch = useDispatch()
    
    const { loading: testsCasesLoading, testCases, testScripts, testSteps } = useSelector(
        (state) => state.testCases)
    
    const key = apikey;
    // const {loading} = useSelector((state) => state.apis)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const handleExecuteTest = () => {

        let temp = []
        testCases.tests.map(testCase => {
            if(testCase.title === test.name){
                temp.push({...testCase, pass: "Running"})
            } else {
                temp.push(testCase)
            }
        })
        dispatch({
            type: "updateTC",
            payload: {...testCases, tests: temp}
        })
        
        if(testScripts?.script?.some(script => script.name === test.name)){
            // console.log('script already exists')
            // console.log(test)
            // Find the script and execute it
            const script = testScripts.script.find(script => script.name === test.name)
            // console.log("Scripts",script)
            // console.log("Name",test.name)
            dispatch(executeIndividualTest(script, user?._id, key))
         } else if (testSteps?.test_steps?.some(step => step.test_case_name === test.name)){
            // console.log('step already exists')
            // console.log(testScripts?.script)
            // Find the step and execute it
            const step = testSteps.test_steps.find(step => step.test_case_name === test.name)

            let baseURL = testScripts?.script[0]?.apis[0]?.baseURL
            dispatch(generateChainandExecute(step, baseURL, key))
         } else {
            console.error('No script or step found')
            // console.log(test)
            // console.log(testSteps?.test_steps)
            // console.log(testScripts?.script)
         } 
    }
    return (
        <div className='issuesDropdownContainer'>

            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ color: "white", marginTop: "-0.7vw" }}
            >
                <IoEllipsisHorizontalSharp />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '15ch',
                        color: "white",
                        opacity: "1",
                        backgroundColor: "#21222d",
                        borderRadius:"4px"
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <MenuItem onClick={handleExecuteTest}>
                        <img className='menuImg' src={Run}></img>
                    Run
                </MenuItem>
                {/* <MenuItem
                    // onClick={handleBugDelete}>
                    >
                          <img className='menuImg' src={Stop}></img>
                   Stop

                </MenuItem>
                        <MenuItem>
                        <img className='menuImg' src={Ignore}></img>
                        Ignore
                        </MenuItem>
                        <MenuItem>
                        <img className='menuImg' src={Delete}></img>
                        Delete
                        </MenuItem>
                        <MenuItem>
                        <img className='menuImg' src={More}></img>
                        More
                        </MenuItem>*/}
            </Menu> 
            <Dialog open={openDialog} onClose={handleCloseDialog} PaperProps={{
                style: {
                    minHeight: "45vh",
                    minWidth: '40vw',
                    backgroundColor: '#191a23',
                    color: '#fff',
                    boxShadow: 'none',
                    borderRadius: '10px',
                    border: '2px solid #fff'
                }
            }}>
                <EditIssueForm
                    // id={bug.id}
                    // issueName={bug.testcasename}
                    // issuePriority={bug.priority}
                    // issueStatus={bug.status}
                />
            </Dialog>
        </div>
    )
}

export default IssuesTableDropdown
