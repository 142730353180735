import { AiOutlineImport } from "react-icons/ai";
import { PiCopy } from "react-icons/pi";

const ReqResDisplay = ({ index, line, setReqBody, reqBody, setResContent, resContent, anchorEl, resAnchorEl, extra, extraRes, setPopOpen, popOpen }) => {
    return <div
        className={
            "group mb-1 w-full py-0.5 pl-1 pr-2 hover:bg-[#0F1011] mr-6 whitespace-pre flex items-center"
        }
        key={index}
        contentEditable="true"
        onKeyDown={(e) => {
            if (e.key === "Enter") {
                let temp = reqBody ? reqBody.split("\n") : resContent.split("\n")
                temp[index] = temp[index] + '\n'
                setReqBody ? setReqBody(temp.join("\n")) : setResContent(temp.join("\n"))
            }
        }}
    >
        {line.includes(":") ? <>
            {line.split(":")[0] + ":"}{" "}
            {extra ? <span className={index in extra ? "text-pink-500" : ""} ref={anchorEl}>
                {index in extra ? extra[index] + "," : line.split(":").slice(1).join(":")}
            </span> : <span className={index in extraRes ? "text-pink-500" : ""} ref={resAnchorEl}>
                {index in extraRes ? extraRes[index] + "," : line.split(":").slice(1).join(":")}
            </span>}
        </> : line}
        {line.includes(":") ? <span
            className="ignore justify-self-end hidden group-hover:block rounded-md text-[#D9D9D9] p-1 bg-[#1D1E20] hover:bg-[#494949] text-sm ml-4 cursor-pointer"
            onClick={() => setPopOpen({ value: true, ind: index, type: setReqBody ? 'req' : 'res', headerKeys: popOpen.headerKeys })}
        >
            <AiOutlineImport />
        </span> : null}
    </div>
};

export default ReqResDisplay;