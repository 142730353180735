import "../SignIn/SignIn.css";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import signin from "../../icons/signin.png";
import { fetchTC } from "../../Actions/tcActions";
import { useAuth } from '../../AuthContext';
import Logo from '../../icons/logoShort.svg'


const OrgDetails = () => {
  // Update the form states
  const [designation, setDesignation] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [workspace, setWorkspace] = useState("");
  const [productUrl, setProductUrl] = useState("");

  // Change the state of the snackbarOpen, alertSeverity and alertMessage
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const [formData, setFormData] = useState({
    designation: '',
    organisation: '',
    workspace: '',
    productUrl: ''
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // let storedUser = user ? user : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  // let isNew = prevNew ? prevNew : localStorage.getItem('isNew') ? JSON.parse(localStorage.getItem('isNew')) : null;

  const { user, updateOrgDetails, logout } = useAuth();
  const isNew = user?.isNew;

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateOrgDetails(formData);
  };

  useEffect(() => {
    if (user.orgDetails && user.waitlisted) {
      navigate("/waitlisted");
    } else if (user && isNew === false) {
      dispatch(fetchTC());
      navigate("/test-suite");
    } else if (user && user.orgDetails && isNew && user.waitlisted === false) {
      navigate("/integrate-sdk");
    }
  }, [user, isNew]);

  useEffect(() => {
    const checkFormValidity = () => {
      const { designation, organisation, workspace, productUrl } = formData;
      const isValid = designation.trim() !== '' && 
                      organisation.trim() !== '' && 
                      workspace.trim() !== '' && 
                      productUrl.trim() !== '';
      setIsFormValid(isValid);
    };
  
    checkFormValidity();
  }, [formData]);

  useEffect(() => {
    // console.log("user", user);
    if (user.orgDetails && user.waitlisted) {
      navigate("/waitlisted");
    } else if (user && isNew === false) {
      dispatch(fetchTC());
      navigate("/test-suite");
    } else if (user && user.orgDetails && isNew && user.waitlisted === false) {
      navigate("/integrate-sdk");
    }
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    await logout();
  }

  return (
    <div className="grid grid-cols-10 w-full overflow-hidden bg-dashboard-dark-700">
      <div className="col-span-3 w-full h-[100vh] relative">
        <img className="w-full h-full" src={signin} alt="" />
        <div className="loginGradient " />
        <div className="absolute top-8 left-8 z-20"><img src={Logo} /></div>
        {/* <div className="w-full absolute h-full top-0 left-0 bg-[#14141E]/30 " /> */}
      </div>
      <div className="col-span-4 text-white p-24 pr-12">
        <div className="font-bold text-h1 text-dashboard-text-pink">Welcome {user?.name}</div>
        <div className="text-dashboard-text-graylight mt-3">We would love to know more about you</div>
        <div className="mt-8">
          <div className="">
            <form onSubmit={handleSubmit}>
              <div className="mt-4">
                <label htmlFor="designation" className="text-[#B0B0B0]">
                  Designation <span className="text-[#F37BB3]">*</span>
                </label>
                <br></br>
                <input
                  className="input w-full mt-2"
                  value={formData.designation}
                  required
                  placeholder="Enter your designation"
                  id="designation"
                  onChange={handleInputChange}
                />
              </div>
              <div className=" mt-4">
                <label htmlFor="organisation" className="text-[#B0B0B0]">
                  Organisation <span className="text-[#F37BB3]">*</span>
                </label>
                <br></br>
                <input
                  required
                  className="input w-full mt-2"
                  value={formData.organisation}
                  id="organisation"
                  placeholder="Enter your organisation name"
                  type="text"
                  onChange={handleInputChange}
                />
              </div>
              <div className="mt-4">
                <label htmlFor="workspace" className="text-[#B0B0B0]">
                  Workspace <span className="text-[#F37BB3]">*</span>
                </label>
                <br></br>
                <input
                  className="input w-full mt-2"
                  value={formData.workspace}
                  required
                  placeholder="Enter your workspace name"
                  id="workspace"
                  onChange={handleInputChange}
                />
              </div>
              <div className="mt-4">
                <label htmlFor="productUrl" className="text-[#B0B0B0]">
                  Product URL <span className="text-[#F37BB3]">*</span>
                </label>
                <br></br>
                <input
                  className="input w-full mt-2"
                  value={formData.productUrl}
                  required
                  type="url"
                  title="Please enter a valid URL starting with http:// or https://"
                  // onInput={(e) => {
                  //   e.target.setCustomValidity("");
                  // }}
                  pattern="https?://.*"
                  placeholder="Enter your product URL"
                  id="productUrl"
                  onChange={handleInputChange}
                />
              </div>

              {/* Add your submit button here */}
              <Button
                variant="contained"
                fullWidth
                type="submit"
                className="loginButton"
                sx={{
                  width: "100%",
                  padding: "14px 16px",
                  height: "48px",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  marginTop: "40px",
                  borderRadius: "6px",
                  border: "1px solid " + (isFormValid ? "#E27AAB" : "#494949"),
                  backgroundColor: isFormValid ? "#E27AAB" : "#494949",
                  ":hover": {
                    backgroundColor:isFormValid ? "#DD6B9F": "#494949",
                    borderColor: isFormValid ? "#FA8DC0" : "#494949",
                  },
                  textTransform: "unset",
                }}
              >
                Proceed
              </Button>
            </form>
          </div>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={10000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </div>
      <div className="col-span-3 flex justify-end items-start w-full p-12">
        <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit max-w-fit px-3 py-1 text-sm ml-10 cursor-pointer" onClick={handleLogout}>
          Log Out
        </div>
      </div>
    </div>
  );
};

export default OrgDetails;