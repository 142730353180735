import SettingsHeading from '../Components/SettingsHeading';
import { Panel } from 'react-resizable-panels';
import { UpcomingOutlined } from "@mui/icons-material";

const TeamSettings = () => {
    return (
        <div>
            <Panel>
                <SettingsHeading variant='team'/>
            </Panel>
            <Panel>
                <div className="flex flex-col items-center justify-center h-[600px] gap-6">
                    <div className="flex items-center justify-center scale-[2]">
                        <UpcomingOutlined className="text-primary" />
                    </div>
                    <div className="text-primary text-h2 font-bold">
                        Coming Soon
                    </div>
                </div>
            </Panel>
        </div>
    );
};

export default TeamSettings;