import Layout from '../Pagelayout/Layout';

const Hoppscotch = () => {
    return <Layout>{/* <div className="w-full grid grid-cols-10 overflow-hidden no-scrollbar">
        <div className="col-span-2">
            <NewNavbar />
        </div> */}
        <div className="col-span-8 h-screen">
            <iframe
                src={"https://api-builder-ui-817077646276.us-central1.run.app"}
                title={"Test"}
                className="w-full h-full"
                style={{ border: 'none'}}
                allowFullScreen
            ></iframe>
        </div>
    {/* </div> */}
    </Layout>
}

export default Hoppscotch