import SettingsHeading from '../Components/SettingsHeading';
import { Panel } from 'react-resizable-panels';
import { LuTrash } from "react-icons/lu";
import { HiMiniPlusSmall } from 'react-icons/hi2';


const WorkspaceSettings = () => {
    return (
        <div className="">
            <Panel>
                <SettingsHeading variant='workspace' />
            </Panel>
            <Panel>
                <div className="flex flex-col gap-16">
                    <div>
                        <div className="text-[#B0B0B0] mb-6 text-lg">
                            Workspace Name
                        </div>
                        <div className="w-full flex gap-6 items-center">
                            <div className="rounded-md w-[548px] bg-[#0F1011] border border-[#2C2E33] flex justify-between items-center gap-2 pr-4 text-[#A0A0A0]">
                                <input
                                    type="text"
                                    placeholder="Workspace Name"
                                    className="w-full px-4 py-2 bg-inherit focus:outline-none active:outline-none focus:border-primary transition-colors duration-200"
                                />
                                <div
                                    className="hover:text-gray-300"
                                    onClick={() => {/* handle clear */ }}
                                >
                                    <LuTrash />
                                </div>
                            </div>
                            <div className='bg-[#D85C93] border border-[#E27AAB] rounded-md px-6 py-2'>
                                Edit
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-[#B0B0B0] mb-6 text-lg">
                            Repository
                        </div>
                        <div className="">
                            <div className="rounded-md mb-2 w-[548px] bg-[#0F1011] border border-[#2C2E33] flex justify-between items-center gap-2 px-4 py-2 text-[#A0A0A0]">
                                Workspace Name
                                <div
                                    className="hover:text-gray-300"
                                    onClick={() => {/* handle clear */ }}
                                >
                                    <LuTrash />
                                </div>
                            </div>
                            <div className="rounded-md mb-2 w-[548px] bg-[#0F1011] border border-[#2C2E33] flex justify-between items-center gap-2 px-4 py-2 text-[#A0A0A0]">
                                Workspace Name
                                <div
                                    className="hover:text-gray-300"
                                    onClick={() => {/* handle clear */ }}
                                >
                                    <LuTrash />
                                </div>
                            </div>
                            <button
                                className="px-2 py-1.5 rounded-md border border-dashboard-dark-400 flex items-center space-x-2 text-dashboard-text-gray"
                            >
                                <HiMiniPlusSmall className="h-4 w-4" />
                                <span>Add New Repository</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
    );
};

export default WorkspaceSettings;