import React, { useEffect, useState } from "react";
import Layout from '../Pagelayout/Layout';
import "./TestSuite.css";
import {
 fetchTC,
 fetchReqResByAPI,
 fetchTestScripts,
 getTestSteps,
 runAllTests,
 getBaseURLs,
 executeIndividualTest,
 deleteTestScript
} from "../../Actions/tcActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CoverageSidebar from "./CoverageSideBar";
import { useAuth } from "../../AuthContext";
import { Panel, PanelGroup } from 'react-resizable-panels';
import { Controls } from './Controls';
import TestCard from "./TestCard";


/*


TestSuite component that renders the test suite page of the application.
The TestSuite component uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
The TestSuite component uses the useEffect hook to fetch the test cases when the component mounts.
The TestSuite component renders the IssuesTable and CoverageComponent components.
The IssuesTable component displays the test cases in a table format.
This component renders on the /test-suite route.
The TestSuite component uses the useNavigate hook from react-router-dom to navigate to different routes.
The CoverageComponent component displays the test coverage statistics.


*/
import { config } from "../../config";


let dev = config.dev
let dburl1 =
 dev === false
   ? "https://server-v3-7qxc7hlaka-uc.a.run.app"
   : "http://localhost:3000";


const TestSuite = () => {
 const navigate = useNavigate();
 const dispatch = useDispatch();


 const [search, setSearch] = useState(null); // Use the search state to store the search query
 const [data, setData] = useState(null)
 const [errorModalOpen, setErrorModalOpen] = useState(false);
 const [selectAll, setSelectAll] = useState(false);
 const [selectedTests, setSelectedTests] = useState([]);


 const {
   loading: testsCasesLoading,
   testCases,
   testScripts,
   testSteps,
 } = useSelector((state) => state.testCases); // Get the test cases from the state


 const { user, apikey } = useAuth(); // Get the user from the AuthContext


 let modalShown = sessionStorage.getItem("modalShown");


 // console.log("modalShown Initialization", modalShown);

 const key = apikey; // Get the key from the API key


 // const { loading: apiListLoading, apiList } = useSelector(state => state.apiList)


 const handleAddTestCase = () => {
   navigate("/add-test-case");
 };


 // function to find the last test case number
 let findLastTestCaseNumber = () => {
   let lastTestCaseNumber = 0;
   if (testCases && testCases?.tests?.length > 0) {
     testCases.tests.forEach((test) => {
       if (test.tcNumber > lastTestCaseNumber) {
         lastTestCaseNumber = test.tcNumber;
       }
     });
   }
   return lastTestCaseNumber;
 };


 let tests = []; // Create an array to store the tests
 let lastTcNumber = findLastTestCaseNumber();


 useEffect(() => {
   if (testCases && testCases?.tests?.length > 0 && testScripts) {
     localStorage.clear();
     testCases.tests.forEach((test) => {
       let script = testScripts.script.find(el => el.name === test.title);
       if (script && script?.apis.length > 0){
         tests.push({
           _id: test.id,
           name: test.title,
           tcNumber: test.tcNumber,
           pass: test.pass,
           time: test.time,
           date: test.date,
           duration: test.duration,
           description: script?.description
         });
       }
     });
     // testScripts?.script.forEach((script)=>{
     //   if (!testCases.tests.some(test => test.title === script.name)) {
     //     tests.push({...script});
     //   }
     // })
     testSteps?.test_steps.forEach((testStep) => {
       if (!testCases.tests.some(test => test.title === testStep.test_case_name)) {
         lastTcNumber++;
         tests.push({
           name: testStep.test_case_name,
           tcNumber: lastTcNumber
         });
       }
       testScripts?.script.forEach((script) => {
         if (!tests.some(test => test.name === script.name)) {
           lastTcNumber++;
           tests.push({
             name: script.name,
             tcNumber: lastTcNumber
           });
         }
       });
     });
     setData({original: tests, current: tests});
     dispatch({
       type: 'SET_TEST_DATA',
       payload: tests
     });
   } else {
     testSteps?.test_steps.forEach((testStep) => {
       if (!testCases?.tests?.some(test => test.title === testStep.test_case_name)) {
         lastTcNumber++;
         tests.push({
           name: testStep.test_case_name,
           tcNumber: lastTcNumber
         });
       }
     });
     testScripts?.script.forEach((script) => {
       if (!tests.some(test => test.name === script.name)) {
         lastTcNumber++;
         tests.push({
           name: script.name,
           tcNumber: lastTcNumber
         });
       }
     });
     setData({original: tests, current: tests});
     dispatch({
       type: 'SET_TEST_DATA',
       payload: tests
     });
   }
 }, [testCases, testScripts]);


 useEffect(() => {
   dispatch(fetchTC());
   dispatch(getBaseURLs());
   dispatch(fetchTestScripts());
   let userid = user
     ? user._id
     : null;
   dispatch(getTestSteps(userid));
   dispatch(fetchReqResByAPI(userid, key));
   // Get the API key from the user
 }, []);


 useEffect(() => {


   let userid = user
     ? user._id
     : null;
   // Create a new EventSource instance to connect to the SSE endpoint
   const eventSource = new EventSource(
     `${dburl1}/events/refreshTC?userid=${userid}`,
     { withCredentials: true }
   );


   // Listen for the "refresh" event from the server
   eventSource.addEventListener("refreshTC", () => {
     // Fetch new data from another endpoint when the "refresh" event is received
     // console.log("Refreshing test cases");
     let userid = user
       ? user._id
       : localStorage.getItem("user")
         ? JSON.parse(localStorage.getItem("user"))._id
         : null;
     dispatch(getTestSteps(userid));
     dispatch(fetchTestScripts());
     dispatch(fetchTC());
   });


   // Cleanup on component unmount
   return () => {
     eventSource.close();
   };
 }, []);


 const runAllTestsHandler = async (e) => {
   try {
     // setLoading(true);
     // setLoading(false);
     await dispatch(runAllTests(key))
     setErrorModalOpen(false);
   } catch (error) {
     // console.log(error);
   }
 };


 useEffect(() => {
   if (testScripts || testSteps) {
     if (!modalShown) {
       // console.log("modalShown", modalShown);
       setErrorModalOpen(true);
       sessionStorage.setItem("modalShown", true);
     }
   }
 }, [testScripts, testSteps])


 const testRunId = ["Test_Run_01"];


 const [runningTests, setRunningTests] = useState({});


 const handleRerun = async (testId, name) => {
   setRunningTests((prev) => ({ ...prev, [testId]: true }));
   try {
     // console.log(`Rerunning test ${testId}`);
     const script = testScripts.script.find((el) => el.name === name);
     await dispatch(executeIndividualTest(script, user?._id, apikey))
     // await new Promise((resolve) => setTimeout(resolve, 2000));
     // console.log(`Test ${testId} completed.`);
   } finally {
     setRunningTests((prev) => ({ ...prev, [testId]: false }));
   }
 }


 const handleSearch = (value) => {
   setSearch(value);
   const searchedData = data?.original?.filter((item) =>
   (item.name.toLowerCase().includes(value.toLowerCase()) || item.description.toLowerCase().includes(value.toLowerCase()))
   );
   setData({...data, current: value === '' ? data?.original : searchedData})
 }

 const deleteTest = async (tcName) => {
  await dispatch(deleteTestScript({name: tcName, apikey}));
 }


 const controlComponentsConfig = [
   { type: "SelectAllButton", props: { selectAll: selectAll, setSelectAll: setSelectAll }, group: "first" },
   { type: "SearchBar", props: { search: search, handleSearch: handleSearch }, group: "first" },
   { type: "FilterButton", group: "second" },
   { type: "SortButton", group: "second" },
   { type: "TestRunSelector", props: { testRunId: testRunId }, group: "second" },
   { type: "AddTestCaseButton", props: { onAddTestCase: handleAddTestCase }, group: "second" },
   { type: "ShareButton", group: "second" },
   { type: "RunTestSuiteButton", props: { onRunTestSuite: runAllTestsHandler }, group: "second" }
];


 return (
   <Layout loading={testsCasesLoading}>
     <Controls components={controlComponentsConfig} />
     <div className="flex flex-col h-full">
       <PanelGroup direction="horizontal" className="flex-1 overflow-auto">
         <Panel minSize={80} className="overflow-auto pb-8 no-scrollbar">
           <div className="flex-1 overflow-auto px-2 py-4 h-full no-scrollbar">
             {data?.current?.map((test, index) => (
               <TestCard
                 index={index}
                 test={test}
                 onRerun={() => handleRerun(test.tcNumber, test.name)}
                 selectedTests={selectedTests}
                 setSelectedTests={setSelectedTests}
                 deleteTest={() => deleteTest(test.name)}
                 key={index}
               />
             ))}
           </div>
         </Panel>
         <Panel minSize={20} className="overflow-auto no-scrollbar">
           <CoverageSidebar coverageTypes={['test', 'http']} />
         </Panel>
       </PanelGroup>
     </div>
   </Layout>
 );
};


export default TestSuite;
