import _ from 'lodash';

// Initial Template Structure
export const initialTemplate = {
  name: '',
  description: '',
  actual_path: '',
  apis: []
};

/**
 * Create a deep clone of the initial template
 * @returns {Object} New template object
 */
export const createNewTemplate = () => {
  return _.cloneDeep(initialTemplate);
};

/**
 * Update entire template by deeply merging new data
 * @param {Object} currentTemplate - Current template state
 * @param {Object} newData - New data to merge
 * @returns {Object} Updated template
 */
export const updateTemplate = (currentTemplate, newData) => {
  return _.merge({}, currentTemplate, newData);
};

/**
 * Update a specific API in the template
 * @param {Object} currentTemplate - Current template state
 * @param {number} apiIndex - Index of API to update
 * @param {Object} updates - Updates to apply to the API
 * @returns {Object} Updated template
 */
export const updateApi = (currentTemplate, apiIndex, updates) => {
  const updatedTemplate = _.cloneDeep(currentTemplate);
  updatedTemplate.apis[apiIndex] = _.merge(
    {}, 
    updatedTemplate.apis[apiIndex], 
    updates
  );
  return updatedTemplate;
};

/**
 * Add a new API to the template
 * @param {Object} currentTemplate - Current template state
 * @param {Object} newApi - New API object to add
 * @returns {Object} Updated template
 */
export const addApi = (currentTemplate, newApi) => {
  const updatedTemplate = _.cloneDeep(currentTemplate);
  updatedTemplate.apis.push(
    _.merge({}, initialTemplate.apis[0], newApi)
  );
  return updatedTemplate;
};

/**
 * Remove an API from the template by index
 * @param {Object} currentTemplate - Current template state
 * @param {number} apiIndex - Index of API to remove
 * @returns {Object} Updated template
 */
export const removeApi = (currentTemplate, apiIndex) => {
  const updatedTemplate = _.cloneDeep(currentTemplate);
  _.pullAt(updatedTemplate.apis, [apiIndex]);
  return updatedTemplate;
};

/**
 * Update a variable in a specific API
 * @param {Object} currentTemplate - Current template state
 * @param {number} apiIndex - Index of API containing the variable
 * @param {string} variableType - Type of variable (e.g., 'variables', 'replace_variables')
 * @param {number} variableIndex - Index of the variable to update
 * @param {Object} updates - Updates to apply to the variable
 * @returns {Object} Updated template
 */
export const updateVariable = (
  currentTemplate, 
  apiIndex, 
  variableType, 
  variableIndex, 
  updates
) => {
  const updatedTemplate = _.cloneDeep(currentTemplate);
  updatedTemplate.apis[apiIndex][variableType][variableIndex] = 
    _.merge({}, 
      updatedTemplate.apis[apiIndex][variableType][variableIndex], 
      updates
    );
  return updatedTemplate;
};

/**
 * Add a variable to a specific API
 * @param {Object} currentTemplate - Current template state
 * @param {number} apiIndex - Index of API to add variable to
 * @param {string} variableType - Type of variable (e.g., 'variables', 'replace_variables')
 * @returns {Object} Updated template
 */
export const addVariable = (currentTemplate, apiIndex, variableType) => {
  const updatedTemplate = _.cloneDeep(currentTemplate);
  updatedTemplate.apis[apiIndex][variableType].push({
    name: '',
    path: ''
  });
  return updatedTemplate;
};

/**
 * Validate template structure (basic validation)
 * @param {Object} template - Template to validate
 * @returns {Object} Validation result
 */
export const validateTemplate = (template) => {
  const errors = [];

  // Check basic required fields
  if (!template.name) errors.push('Template name is required');
  if (!template.apis || template.apis.length === 0) {
    errors.push('At least one API is required');
  }

  // Validate each API
  template.apis.forEach((api, index) => {
    if (!api.name) errors.push(`API at index ${index} is missing a name`);
    if (!api.endpoint) errors.push(`API at index ${index} is missing an endpoint`);
    if (!api.method) errors.push(`API at index ${index} is missing a method`);
  });

  return {
    isValid: errors.length === 0,
    errors
  };
};


