import React from "react";
import DevzeryIcon from "../../icons/devzery-icon.svg";
import "./WaitlistedPage.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";
/*

WelcomePage component that renders the welcome page of the application.
The WelcomePage component uses the DevzeryIcon component to display the Devzery logo.
The WelcomePage component contains a title, a paragraph, and a button to get started.

*/


export const WaitlistedPage = () => {
  const { logout } = useAuth();

const handleLogout = async (e) => {
  e.preventDefault();
  localStorage.clear();
  sessionStorage.clear();
  await logout();
  window.location.href = "/login";
}
  // console.log("WaitlistedPage");
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-[#080808] text-white">
      <img src={DevzeryIcon} />
      <div className="mt-8 mb-4 text-[40px] font-semibold">Oh no!</div>

      {/* <p className="text-[#DCDCDC] text-center">
        Experience firsthand how Devzery&#39;s AI-powered API regression testing
        platform
        <br />
        streamlines testing and speeds up your development cycle.
      </p> */}
      <p className="text-[#DCDCDC] text-center">
        Currently we have onboarded 250+ users and you have been waitlisted.
        <br />
        You will be able to access as soon as we open for new users.
      </p>

      <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit max-w-fit px-4 py-1 mt-20 cursor-pointer" onClick={handleLogout}>
          Log Out
        </div>
      {/* <Link to="/login">
        <div className="w-[30vw] bg-[#D9509B] border border-1 border-[#FF86BE] rounded-md py-3 text-center mt-12">
          Get Started
        </div>
      </Link> */}

      {/* <DevzeryIcon className="devzery-icon-instance" /> */}
    </div>
  );
};
